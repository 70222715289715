import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { HStack, Image, Pressable, Text, VStack } from 'native-base'
import { ModeratorCardProps } from '../../../../domain/models/interfaces/explore/moderatorsInterfaces'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import { MemberScreenParams } from '../../screens/MemberScreen'

const ModeratorCard = ({
  moderator,
  firstCard = false,
  width = '976px',
  isModeratorWebScreen = false,
}: ModeratorCardProps) => {
  const isDesktop = useDesktopBreakpoint()
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  return (
    <Pressable
      onPress={() =>
        navigation.navigate('ModeratorDetail', { moderatorId: moderator.id })
      }>
      <HStack
        borderColor="grey.200"
        borderWidth={1}
        width={width}
        height="88px"
        alignSelf="center"
        bg="white"
        borderRadius="10px"
        p={4}
        ml={!firstCard && isDesktop && !isModeratorWebScreen ? 4 : 0}
        mb={isModeratorWebScreen ? 2 : 4}
        mt={firstCard && !isDesktop ? 4 : !firstCard && !isDesktop ? 0 : 4}>
        <Image
          source={{ uri: moderator.photoUrl }}
          alt="Moderator photo"
          borderRadius="10px"
          width="56px"
          height="56px"
          mr={4}
        />
        <VStack flex={1} justifyContent="space-evenly">
          <Text
            color="text.900"
            fontSize="14px"
            fontWeight="medium"
            lineHeight="18px"
            numberOfLines={2}>
            {moderator.name}
          </Text>
          {moderator.title && (
            <Text
              color="text.500"
              fontSize="12px"
              fontWeight="medium"
              lineHeight="14px"
              numberOfLines={2}>
              {moderator?.title}
            </Text>
          )}
        </VStack>
      </HStack>
    </Pressable>
  )
}

export default ModeratorCard

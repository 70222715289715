import { Box, Button, HStack, Image, Text } from 'native-base'
import { StyleSheet, Text as TextNative } from 'react-native'
import { useRenderFocusAreaIcon } from '../../../hooks/useRenderFocusAreasIcon'
import ActivityCard from '../../_shared/card/ActivityCard'

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import analytics from '../../../../../domain/services/analytics/AnalyticsService'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import { MemberScreenParams } from '../../../screens/MemberScreen'
import FaIcon from '../../_shared/FaIcon'

const CompassImage = require('../../../assets/images/voyages/compass.png')

const styles = StyleSheet.create({
  bold: {
    fontFamily: 'Poppins_700Bold',
  },
})

const FocusArea = ({ focusArea }) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  const isDesktop = useDesktopBreakpoint()
  const { icon, color } =
    focusArea !== null && useRenderFocusAreaIcon(focusArea.name)

  if (focusArea === null) {
    return
  }

  const goToActivity = () => {
    navigation.navigate('Activity', {
      activityId: focusArea.nextPath.nextActivity.id,
      stepId: focusArea.nextPath.nextActivity.stepId,
    })

    analytics.track('Path Started', {
      title: focusArea.nextPath.name,
      focusArea: focusArea.name,
      progress: focusArea.nextPath.stepsCompleted,
      url: window.location?.href,
    })
    analytics.track('Focus Area Started', {
      path: focusArea.nextPath.name,
      title: focusArea.name,
      progress: focusArea.nextPath.stepsCompleted,
    })
  }

  return (
    <Box
      mb={4}
      borderRadius="20px"
      bg="white"
      p={4}
      borderColor="grey.200"
      borderWidth={1}>
      <HStack alignItems="center">
        <HStack
          borderRadius="full"
          alignItems="center"
          justifyContent="center"
          width="20px"
          height="20px"
          background={color}>
          <FaIcon name={icon} isSolid color="white" size={12} />
        </HStack>
        <Text ml={2.5} fontSize="12px" color="muted.900" fontWeight={500}>
          {focusArea.name}
        </Text>
      </HStack>
      <Text my={2} fontSize="16px">
        <Text color="primary.900">
          <TextNative style={styles.bold}>
            {focusArea.minutesEarned === 0 ? `Start with ` : `Continue `}
          </TextNative>
        </Text>
        <Text color={color}>
          <TextNative style={styles.bold}>
            {focusArea.minutesEarned === 0
              ? focusArea.name
              : focusArea.nextPath !== null && focusArea.nextPath.name}
          </TextNative>
        </Text>
      </Text>
      {focusArea.minutesEarned === 0 && (
        <Text fontSize="12px" color="primary.900" mb={4}>
          {`${focusArea.description.split('.')[0]}.`}
        </Text>
      )}
      {focusArea.name === 'Values' && (
        <Box w={isDesktop ? '326px' : '100%'} alignSelf="center">
          <Image
            alignSelf="center"
            style={{ height: 196, width: 196 }}
            source={CompassImage}
            alt="Compass"
          />
          <Button
            mt={4}
            onPress={async () => {
              if (focusArea.nextPath.nextActivity.id === 'COMPASS') {
                navigation.navigate('Compass')
              } else {
                analytics.track('Path Started', {
                  title: focusArea.nextPath.name,
                  focusArea: focusArea.name,
                  progress: focusArea.nextPath.stepsCompleted,
                  url: window.location?.href,
                })

                analytics.track('Focus Area Started', {
                  path: focusArea.nextPath.name,
                  title: focusArea.name,
                  progress: focusArea.nextPath.stepsCompleted,
                })
                goToActivity()
              }
            }}>
            <Text color="white" fontSize="16px" fontWeight={500}>
              View activity
            </Text>
          </Button>
        </Box>
      )}
      {focusArea.nextPath !== null &&
        focusArea.nextPath.nextActivity &&
        focusArea.name !== 'Values' && (
          <Box alignSelf="center" w={isDesktop ? '326px' : '100%'}>
            <ActivityCard
              activity={focusArea.nextPath.nextActivity}
              onPress={async () => goToActivity()}
            />
            <Button mt={4} onPress={async () => goToActivity()}>
              <Text color="white" fontSize="16px" fontWeight={500}>
                {focusArea.minutesEarned === 0
                  ? 'Start activity'
                  : 'View activity'}
              </Text>
            </Button>
          </Box>
        )}
      <HStack mt={4} justifyContent="space-between" alignItems="center">
        <HStack
          px={2}
          borderRadius="48px"
          height="24px"
          alignItems="center"
          bg={
            focusArea.nextPath !== null &&
            focusArea.nextPath.stepsCompleted === 0
              ? 'muted.200'
              : 'success.200'
          }>
          {focusArea.nextPath !== null && (
            <Text
              fontSize="12px"
              color="muted.900"
              fontWeight={
                700
              }>{`${focusArea.nextPath.stepsCompleted} / ${focusArea.nextPath.steps}`}</Text>
          )}
        </HStack>
        <Button
          variant="link"
          p={0}
          onPress={() =>
            navigation.navigate('Path', {
              id: focusArea.nextPath.id,
              focusArea: focusArea.name,
            })
          }>
          <Text fontSize="12px" fontWeight={600}>
            View full path
          </Text>
        </Button>
      </HStack>
    </Box>
  )
}

export default FocusArea

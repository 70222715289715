import { Box } from 'native-base'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import SignupForm from '../../components/onboarding/SignupForm'
import FormLayout from '../../layouts/FormLayout'

const SignupScreen = ({ route, navigation }) => {
  const { email, accessCode } = route.params
  const referralCode = route.params?.referralCode
  const organizationCode = route.params?.organizationCode
  const isDesktop = useDesktopBreakpoint()

  if (organizationCode) {
    navigation.replace('OchsnerSignup', { accessCode: organizationCode })
  }

  return (
    <FormLayout
      bg="white"
      extraButton={{ text: 'Log in', navigation: 'Login' }}>
      {!isDesktop && (
        <Box mt={-4} mx={-6} borderTopWidth={1} borderTopColor="muted.50" />
      )}
      <SignupForm
        email={email}
        referralCode={referralCode}
        accessCode={accessCode}
      />
    </FormLayout>
  )
}

export default SignupScreen

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Button, Heading, Text, VStack } from 'native-base'
import { useState } from 'react'
import { Linking, useWindowDimensions } from 'react-native'
import analytics from '../../../../domain/services/analytics/AnalyticsService'
import { useMobileBreakpoint } from '../../../../domain/services/useBreakpoint'
import { useCabanaApi } from '../../providers/CabanaApiProvider'
import { GuestScreenParams } from '../../screens/GuestScreen'

interface AccessCodeFormProps {
  email: string
  referralCode?: string
  accessCode?: string
}

const SignupForm = ({
  email,
  referralCode,
  accessCode,
}: AccessCodeFormProps) => {
  const { AuthPasswordlessApi } = useCabanaApi()
  const isMobile = useMobileBreakpoint()
  const navigation =
    useNavigation<NativeStackNavigationProp<GuestScreenParams>>()
  const { width } = useWindowDimensions()
  const [createAccountError, setCreateAccountError] = useState(false)

  const createAccount = (accessCode, referralCode) =>
    AuthPasswordlessApi.createAccount(email, accessCode, referralCode)
      .then(() => {
        navigation.navigate('AccountCreated', { userEmail: email })
        if (referralCode) {
          analytics.track('Referral Completed', {
            'Referral-Code': referralCode,
          })
        }
      })
      .catch((error) => {
        console.debug(error)
        setCreateAccountError(true)
      })

  const hasAccessCode = () => {
    if (accessCode || referralCode) {
      createAccount(accessCode, referralCode)
    } else {
      AuthPasswordlessApi.lookupAccessCode(email)
        .then((response) => {
          createAccount(response.data.accessCode, null)
        })
        .catch((error) => {
          navigation.navigate('VerifyEligibility', { email })
        })
    }
  }

  return (
    <>
      <VStack h="100%" w="100%" style={{ gap: 12 }}>
        {createAccountError ? (
          <>
            <Heading fontSize="24px" mt="3" color="primary.900">
              Uh oh!
            </Heading>
            <Text color="primary.900" fontSize="16px" mb={6}>
              We've encountered an error while creating your account.
            </Text>
            <Button
              onPress={() => {
                Linking.openURL('mailto:support@mycabana.health')
              }}>
              Contact Support
            </Button>
          </>
        ) : (
          <>
            <Heading fontSize={'2xl'} mt="3" color="primary.900">
              Welcome
            </Heading>
            <Text fontSize={'lg'} fontWeight={600} color="primary.900">
              {email}
            </Text>
            <Text fontSize={'md'} color="primary.900">
              We didn't find a Cabana account for your email, but it's easy to
              create one.
            </Text>
            <Button onPress={hasAccessCode}>Create account</Button>
          </>
        )}
      </VStack>
    </>
  )
}

export default SignupForm

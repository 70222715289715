import { FlashList } from '@shopify/flash-list'
import { addDays } from 'date-fns/esm'
import { Box, Text, View } from 'native-base'
import { useCallback } from 'react'
import ActivityCard from '../_shared/card/ActivityCard'

const GroupsOnDateList = ({
  setIndexOnScroll,
  sectionListRef,
  onScrollBegin,
  dateEvents,
  onLiveGroupPress,
  onScrollAnimationEnd,
  visible = true,
}) => {
  const onCheckViewableItems = useCallback(
    ({ viewableItems, changed }) => {
      if (viewableItems.length > 0) {
        const stickyTitle = viewableItems.find(
          (event) => event.item.type === 'title'
        )
        if (stickyTitle) {
          const date = addDays(
            stickyTitle.item.date,
            viewableItems[0] != stickyTitle ? -1 : 0
          )
          setIndexOnScroll(date)
        }
      }
    },
    [dateEvents, setIndexOnScroll]
  )

  const stickyHeaderIndices = dateEvents
    .map((item, index) => {
      if (item?.type === 'title') {
        return index
      } else {
        return null
      }
    })
    .filter((item) => item !== null) as number[]

  return (
    <View
      flex={1}
      borderTopRadius="2xl"
      overflow="hidden"
      opacity={visible ? 100 : 0}>
      <FlashList
        data={dateEvents}
        renderItem={({ item, index, target }) => {
          if (item?.type === 'title') {
            return (
              <Box
                pt="8px"
                backgroundColor={
                  target === 'StickyHeader' ? 'muted.100' : 'transparent'
                }
                px={target === 'StickyHeader' ? '24px' : undefined}
                borderBottomColor="muted.300"
                borderBottomWidth={1}
                mx={target === 'StickyHeader' ? undefined : '24px'}
                mb="15px">
                <Text
                  color="primary.700"
                  fontSize="14px"
                  fontWeight="700"
                  top="-3px">
                  {item.title}
                </Text>
              </Box>
            )
          } else if (item?.type === 'empty')
            return (
              <Box
                mx="24px"
                mb="16px"
                p="16px"
                borderWidth="1px"
                borderColor="muted.300"
                borderRadius="10px">
                <Text color="text.500">No events scheduled</Text>
              </Box>
            )
          return (
            <Box mx="24px" pb="15px">
              <ActivityCard activity={item} onPress={onLiveGroupPress(item)} />
            </Box>
          )
        }}
        getItemType={(item) => {
          return typeof item === 'string' ? 'sectionHeader' : 'row'
        }}
        stickyHeaderIndices={stickyHeaderIndices}
        estimatedItemSize={82}
        viewabilityConfig={{
          itemVisiblePercentThreshold: 20,
        }}
        contentContainerStyle={{
          width: '100%',
          height: 750,
          backgroundColor: 'white',
        }}
        ref={sectionListRef}
        onViewableItemsChanged={onCheckViewableItems}
        overrideItemLayout={(layout, item, index, maxColumns) => {
          switch (item.type) {
            case 'title':
              layout.size = 45
              return
            case 'empty':
              layout.size = 71
              return
            default:
              layout.size = 103
              return
          }
        }}
        bounces={false}
        onMomentumScrollEnd={onScrollAnimationEnd}
        onTouchStart={onScrollBegin}
        onScroll={onScrollAnimationEnd}
      />
    </View>
  )
}

const SectionDate = ({ section: { title } }) => (
  <Box
    px="24px"
    width="100%"
    bg="white"
    justifyContent="center"
    borderBottomColor="muted.300"
    borderBottomWidth={1}
    height="33px"
    mb="15px">
    <Text fontSize="14" fontWeight="700">
      {title}
    </Text>
  </Box>
)

export default GroupsOnDateList

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useCabanaApi } from '../providers/CabanaApiProvider'
import { MemberScreenParams } from '../screens/MemberScreen'

interface SelectedAnswer {
  question: number
  answers: number[]
}

export const useScreenerAnswersMutation = () => {
  const { VoyageApi } = useCabanaApi()
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (selectedAnswers: SelectedAnswer[]) => VoyageApi.screener(selectedAnswers),
    {
      onSuccess: (response) => {
        queryClient.setQueryData(['focusAreas'], response.data)
      },
    }
  )

  return mutation
}

export const useBalancedSelf = () => {
  const { VoyageApi } = useCabanaApi()

  const { data, isLoading } = useQuery({
    queryKey: ['BalancedSelf'],
    queryFn: () => VoyageApi.balancedSelf(),
  })

  return {
    data,
    isLoading,
  }
}

export const useFocusArea = (focusArea: string) => {
  const { VoyageApi } = useCabanaApi()

  const { data, isLoading } = useQuery({
    queryKey: ['FocusArea', focusArea],
    queryFn: () => VoyageApi.focusArea(focusArea),
  })

  return {
    data,
    isLoading,
  }
}

export const usePath = (focusArea: string, id: string) => {
  const { VoyageApi } = useCabanaApi()

  const { data, isLoading } = useQuery({
    queryKey: ['Path', focusArea, id],
    queryFn: () => VoyageApi.path(focusArea, id),
  })

  return {
    data,
    isLoading,
  }
}

export const useCompleteStepMutation = (compass = false) => {
  const { VoyageApi } = useCabanaApi()
  const queryClient = useQueryClient()
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()

  const mutation = useMutation(
    (stepId: string) => VoyageApi.completeStep(stepId),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['nextPathOrStep'], data)
        queryClient.invalidateQueries(['Path'])
        queryClient.invalidateQueries(['BalancedSelf'])
        queryClient.invalidateQueries(['FocusArea'])
        !compass && navigation.navigate('Completed')
      },
    }
  )

  const { data } = useQuery(['nextPathOrStep'], () => mutation.data, {
    enabled: !!mutation.data,
  })

  return { mutation, data }
}

export const useCompass = () => {
  const { VoyageApi } = useCabanaApi()

  const { data, isLoading } = useQuery({
    queryKey: ['Compass'],
    queryFn: () => VoyageApi.compass(),
  })

  return {
    data,
    isLoading,
  }
}

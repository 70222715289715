import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, HStack, ScrollView, Text } from 'native-base'
import { Pressable } from 'react-native'
import {
  useDesktopBreakpoint,
  useMobileBreakpoint,
} from '../../../../domain/services/useBreakpoint'
import { TimeFilters } from '../../constants/filterConstants'
import { MemberScreenParams } from '../../screens/MemberScreen'

const TimeFilterRowView = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  const isMobile = useMobileBreakpoint()
  const isDesktop = useDesktopBreakpoint()

  const margin = isMobile || isDesktop ? '24px' : '40px'

  return (
    <ScrollView
      horizontal
      mt="16px"
      mx={'-' + margin}
      scrollEnabled={!isDesktop}
      showsHorizontalScrollIndicator={false}>
      {TimeFilters.map((time, index) => (
        <Pressable
          key={time}
          onPress={() => {
            navigation.navigate('Search', {
              timeFilters: index,
            })
          }}>
          <Box
            borderRadius="2xl"
            ml={time === TimeFilters[0] ? margin : isMobile ? '8px' : '16px'}
            mr={
              time === TimeFilters[TimeFilters.length - 1] ? margin : undefined
            }
            py="6px"
            width={isMobile ? '76px' : isDesktop ? '103.8px' : '94px'}
            backgroundColor="white"
            borderColor="primary.700"
            borderWidth="1px"
            alignItems="center"
            mb={!isMobile && '2px'}>
            <HStack>
              <Text color="primary.700" fontWeight="500" fontSize="14px">
                {time + ' min'}
              </Text>
            </HStack>
          </Box>
        </Pressable>
      ))}
    </ScrollView>
  )
}

export default TimeFilterRowView

import { Button, Flex, HStack, Text, VStack } from 'native-base'

interface tabType {
  title: string
  content: JSX.Element
  secondaryComponent: JSX.Element
  onOpenCallback: any
}

const Tabs = ({ tabSelected, selectTab, tabsData, hasShadow = true }) => {
  return (
    <VStack h="100%" w="100%">
      <HStack
        zIndex="1"
        bg={hasShadow ? 'white' : 'transparent'}
        shadow={hasShadow ? '9' : 0}
        justifyContent="space-around"
        px="3">
        {Object.entries(tabsData).map((item: [string, tabType], index) => {
          const tabKey = item[0]
          const { title, secondaryComponent, onOpenCallback } = item[1]
          return (
            <Flex key={index}>
              <Button
                variant="ghost"
                colorScheme={tabKey === tabSelected ? 'secondary' : ''}
                borderBottomColor={
                  tabKey === tabSelected ? 'secondary.500' : 'transparent'
                }
                borderBottomWidth="3px"
                borderBottomRadius="0"
                pt="13px"
                onPress={() => {
                  selectTab(tabKey)
                  if (onOpenCallback) {
                    onOpenCallback()
                  }
                }}>
                <HStack alignItems={'center'}>
                  <Text
                    color={
                      tabKey === tabSelected ? 'secondary.500' : 'text.900'
                    }
                    fontWeight="medium"
                    p="6px">
                    {title}
                  </Text>
                  {tabKey !== tabSelected && secondaryComponent}
                </HStack>
              </Button>
            </Flex>
          )
        })}
      </HStack>
      <Flex flex={1}>{tabsData[tabSelected].content}</Flex>
    </VStack>
  )
}

export default Tabs

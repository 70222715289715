import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, Heading, HStack, ScrollView, Text } from 'native-base'
import { Pressable, useWindowDimensions } from 'react-native'
import {
  useDesktopBreakpoint,
  useMobileBreakpoint,
  useTabletBreakpoint,
} from '../../../../domain/services/useBreakpoint'
import { MemberScreenParams } from '../../screens/MemberScreen'
import ContentCard from '../_shared/card/ContentCard'

const RecentlyAdded = ({ data, onPressActivity }) => {
  const { width } = useWindowDimensions()
  const isMobile = useMobileBreakpoint()
  const isDesktop = useDesktopBreakpoint()
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  const isTablet = useTabletBreakpoint()
  const margin = isTablet ? 40 : 24
  const marginInPx = margin + 'px'
  const numberOfVisibleCards = isTablet ? 4 : 2
  const cardWidth =
    (width - margin * 2 - (isTablet ? 3 : 1) * 8) / numberOfVisibleCards

  return (
    <Box>
      <HStack justifyContent="space-between">
        <Heading size="sm" color="primary.900">
          Popular content
        </Heading>
        <Pressable
          onPress={() =>
            navigation.navigate('Search', {
              categoryFilters: 1,
            })
          }>
          <Text color="primary.900">see all</Text>
        </Pressable>
      </HStack>
      <ScrollView
        horizontal
        mx={'-' + marginInPx}
        showsHorizontalScrollIndicator={false}>
        {data.map((activity, index) => (
          <Box
            ml={index === 0 ? marginInPx : isTablet || isMobile ? '4px' : '8px'}
            mr={
              index === data.length - 1
                ? marginInPx
                : isTablet || isMobile
                ? '4px'
                : '8px'
            }
            mt="16px"
            key={activity.id}>
            <ContentCard
              activity={activity}
              width={isDesktop ? '224px' : cardWidth + 'px'}
              onPress={() => {
                onPressActivity(activity)
              }}
            />
          </Box>
        ))}
      </ScrollView>
    </Box>
  )
}

export default RecentlyAdded

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, Divider, HStack, SectionList, Text } from 'native-base'
import { useWindowDimensions } from 'react-native'
import useGetShadowProps from '../../../hooks/useGetShadowProps'
import { useMyGroups } from '../../../queries/activitiesQueries'
import { MemberScreenParams } from '../../../screens/MemberScreen'
import EmptyListComponent from '../../EmptyListComponent'
import ActivityCard from '../../_shared/card/ActivityCard'
import ProfileContainer from '../../_shared/layout/ProfileContainer'
import { parseSections } from './parseSections'

const EmptyImage = require('../../../assets/images/vibe-check/empty-groups.png')

const MyGroupsMobile = () => {
  const { registeredGroups, isLoading } = useMyGroups()
  const sections = parseSections(registeredGroups)
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  const { height } = useWindowDimensions()
  const shadow = useGetShadowProps(15)

  const borderStyle = {
    borderColor: 'muted.300',
    borderWidth: '1',
    borderRadius: '10px',
  }

  const borderStyleCompleted = {
    borderColor: 'primary.700',
    borderWidth: '1',
    borderRadius: '10px',
  }

  return (
    <ProfileContainer headerText="My groups" title="Group history">
      <>
        {!isLoading && (
          <>
            <SectionList
              sections={sections}
              renderSectionHeader={({ section }) => {
                return (
                  <Box>
                    <Divider color="muted.300" mt={4} />
                    <Text fontWeight="700" mt={4} mb={5} color="primary.900">
                      {`My ${section.title}`}
                    </Text>
                  </Box>
                )
              }}
              renderItem={({ section, item }) => {
                return section.title === 'past groups' ? (
                  <Box>
                    <Text
                      fontSize="16px"
                      fontWeight="700"
                      mb={5}
                      color="primary.900">
                      {item.month}
                    </Text>

                    {item.data.map((group) => (
                      <Box pb="24px" key={group.id}>
                        <ActivityCard
                          hasShadow={false}
                          borderStyle={
                            group.registration === 'ATTENDING' ||
                            group.registration === 'COMPLETED' ||
                            group.registration === 'MODERATING'
                              ? borderStyleCompleted
                              : borderStyle
                          }
                          reduceOpacityOnComplete={false}
                          activity={group}
                          onPress={() =>
                            navigation.navigate('Activity', {
                              activityId: group.id,
                            })
                          }
                        />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box pb="24px">
                    <ActivityCard
                      borderStyle={
                        item.cardBorderState === 'COMPLETED' ||
                        item.cardBorderState === 'ATTENDING'
                          ? borderStyleCompleted
                          : borderStyle
                      }
                      hasShadow={false}
                      reduceOpacityOnComplete={false}
                      activity={item}
                      onPress={() =>
                        navigation.navigate('Activity', { activityId: item.id })
                      }
                    />
                  </Box>
                )
              }}
              mx="14px"
              keyExtractor={(item) => item.id}
              showsVerticalScrollIndicator={false}
              maxH={height * 0.8}
              stickySectionHeadersEnabled={false}
              ListEmptyComponent={
                <HStack
                  borderRadius="20px"
                  p={4}
                  mx={5}
                  bg="white"
                  flexDirection="column"
                  {...shadow}>
                  <EmptyListComponent
                    title="Group history"
                    description="Participate or register in a live-group to view history"
                    buttonText="View upcoming groups"
                    buttonAction={() => navigation.navigate('LiveGroups')}
                    imageSource={EmptyImage}
                  />
                </HStack>
              }
              px="2px"
              pb="20px"
            />
          </>
        )}
      </>
    </ProfileContainer>
  )
}

export default MyGroupsMobile

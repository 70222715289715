import { Box, Heading, Image, StatusBar } from 'native-base'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import AudioPlayer from '../components/explore/audio/AudioPlayer'
import BackButton from '../components/navigation/BackButton'
import { useGetActivityDetails } from '../queries/activitiesQueries'

const background = require('../assets/images/live-groups/violetBackground.png')

const AudioPlayerScreen = ({ route }) => {
  const { activityId } = route.params
  const { activity, isLoading } = useGetActivityDetails(activityId)
  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <StatusBar barStyle="light-content" />
      <Image
        source={background}
        position="absolute"
        width="100%"
        height="100%"
        alt="Background waves"
      />
      <Box width="100%" height="100%" px="4" safeAreaTop>
        <BackButton color="white" />
        <Heading color="white" textAlign="center" marginTop="45px">
          {activity?.title}
        </Heading>
        <AudioPlayer activity={activity} />
      </Box>
    </GestureHandlerRootView>
  )
}

export default AudioPlayerScreen

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, Button, Image, ScrollView, Text } from 'native-base'
import { Platform } from 'react-native'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import { checkCompassAnswers } from '../../../../helpers/checkCompassAnswers'
import { useUserProfileContext } from '../../../providers/UserProfileProvider'
import { useCompass } from '../../../queries/voyageQueries'
import { MemberScreenParams } from '../../../screens/MemberScreen'
import BackButton from '../../navigation/BackButton'
import Header from '../../_shared/Header'
import CompassValues from './CompassValues'

const Wave = require('../../../assets/images/moderators/Wave.png')
const CompassImage = require('../../../assets/images/voyages/compass.png')

const Compass = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  const isDesktop = useDesktopBreakpoint()
  const { userProfile } = useUserProfileContext()
  const { data, isLoading } = useCompass()
  const compassCompleted = checkCompassAnswers(data)

  return (
    <>
      {!isDesktop ? (
        <Header source={Wave} backButtonTitle="" />
      ) : (
        <Box>
          <BackButton customText="" />
        </Box>
      )}
      <ScrollView
        borderRadius={isDesktop ? '20px' : 0}
        shadow={isDesktop ? 4 : 0}
        my={isDesktop ? 2 : 0}
        backgroundColor="white"
        pt={isDesktop ? 10 : 6}
        p={isDesktop ? 10 : 4}
        showsVerticalScrollIndicator={false}>
        <Box display="flex" flexDirection={isDesktop ? 'row' : 'column'}>
          <Box width={!isDesktop ? '100%' : '60%'}>
            <Text mb={4} color="primary.900" fontSize="24px" fontWeight={500}>
              Inner Compass
            </Text>
            <Text color="primary.900">
              We're here to guide you through discovering your core values.
              Remember, you're not in this alone! If you haven’t already,
              schedule your guided session with Kristine.
            </Text>
            <Button
              alignSelf="center"
              width={!isDesktop ? '100%' : '342px'}
              my={3}
              variant="outline"
              onPress={() => {
                navigation.navigate('Activity', {
                  activityId: 'recfji1AscvPfdyy1',
                })
              }}>
              <Text color="text.900" fontSize="16px" fontWeight={500}>
                Set up Guided Session
              </Text>
            </Button>
            {!isDesktop &&
              (!compassCompleted ? (
                <Image
                  mt={2}
                  mb={3}
                  alignSelf="center"
                  style={{ height: 237, width: 237 }}
                  source={CompassImage}
                  alt="Compass"
                />
              ) : (
                <CompassValues data={data} />
              ))}
            <Text color="primary.900">
              This internal compass and the journey you are about to embark on
              is arguably the most important gift you can give yourself: the
              ability to navigate your inner world.
            </Text>
            <Button
              alignSelf="center"
              width={!isDesktop ? '100%' : '342px'}
              mt={6}
              onPress={() => {
                navigation.navigate('CompassForm')
              }}>
              <Text color="white" fontSize="16px" fontWeight={500}>
                Begin with North star
              </Text>
            </Button>
          </Box>
          {isDesktop && (
            <Box width={!isDesktop ? '100%' : '40%'}>
              {compassCompleted ? (
                <CompassValues data={data} />
              ) : (
                <Image
                  mt={2}
                  mb={3}
                  alignSelf="center"
                  style={{ height: 300, width: 300 }}
                  source={CompassImage}
                  alt="Compass"
                />
              )}
            </Box>
          )}
        </Box>
        {Platform.OS === 'ios' && <Box height="50px" />}
      </ScrollView>
    </>
  )
}

export default Compass

import { Box, Button, Divider, Flex, Text, VStack } from 'native-base'
import { useWindowDimensions } from 'react-native'
import Modal from 'react-native-modal'
import { useMobileBreakpoint } from '../../../../../domain/services/useBreakpoint'
import FaIcon from '../FaIcon'

const BottomSheet = ({
  isOpen,
  title = undefined,
  body,
  actions = <></>,
  width = '390px',
  atTop = null,
  atRight = null,
  atBottom = null,
  atLeft = null,
  handleClose,
  hasDivider = true,
  closeButtonText = 'Close',
  centerTitle = false,
  hasCloseOnBottom = true,
  bottomComponent = <></>,
  bodyPadding = '24px',
  containerStyle = {},
}) => {
  const isMobile = useMobileBreakpoint()
  const windowDimensions = useWindowDimensions()

  return (
    <Modal
      useNativeDriverForBackdrop={true}
      isVisible={isOpen}
      deviceHeight={windowDimensions.height}
      deviceWidth={windowDimensions.width}
      style={{ margin: 0, ...containerStyle }}
      onBackdropPress={handleClose}
      animationOutTiming={500}>
      <VStack
        bg="white"
        w={isMobile ? '100%' : width}
        mt={atTop ? '0' : 'auto'}
        mr={atRight ? '0' : 'auto'}
        mb={isMobile ? '0' : atBottom ? '0' : 'auto'}
        ml={atLeft ? '0' : 'auto'}
        borderTopRadius="lg"
        borderBottomRadius={isMobile ? '0' : 'lg'}
        divider={hasDivider && <Divider />}>
        {title && (
          <>
            <Text
              p={title && '3'}
              fontSize="lg"
              fontWeight="bold"
              alignSelf={centerTitle ? 'center' : undefined}>
              {title}
            </Text>
          </>
        )}
        <Button
          position="absolute"
          variant="ghost"
          borderRadius="full"
          top="4px"
          right="4px"
          p="3"
          zIndex="3"
          onPress={handleClose}>
          <FaIcon name="xmark" size={20} />
        </Button>
        <Box
          mb={isMobile && '10'}
          mt={!title && '24px'}
          p={body && bodyPadding}>
          {body}
        </Box>
        {!isMobile && hasCloseOnBottom && (
          <Flex align="flex-end" p="3">
            <Button.Group space={2}>
              <Button variant="outline" onPress={handleClose}>
                {closeButtonText}
              </Button>
              {actions}
            </Button.Group>
          </Flex>
        )}
        {bottomComponent}
      </VStack>
    </Modal>
  )
}

export default BottomSheet

const AttendeeState = {
  NotJoined: 'NOT_JOINED',
  Orientation: 'ORIENTATION',
  Waiting: 'WAITING',
  Joined: 'JOINED',
  Left: 'LEFT',
  FeedbackRequested: 'FEEDBACK_REQUESTED',
  FeedbackSubmitted: 'FEEDBACK_SUBMITTED',
  Complete: 'COMPLETE',
}

export default AttendeeState

import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import WeeklyInsights from '../../components/insights/WeeklyInsights'

const InsightsScreen = () => {
  const isDesktop = useDesktopBreakpoint()

  return !isDesktop && <WeeklyInsights />
}

export default InsightsScreen

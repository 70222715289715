import { StatusBar, View } from 'native-base'
import VibeCheckForm from '../components/vibeCheck/VibeCheckForm'
import FormLayout from '../layouts/FormLayout'

interface VibeCheckScreenProps {
  closeModal?: () => void
}

const VibeCheckScreen = ({ closeModal }: VibeCheckScreenProps) => {
  return (
    <View keyboardShouldPersistTaps="always" flex={1}>
      <FormLayout
        padding="0"
        hasSafeAreaY={false}
        hasScrollBar={false}
        autoBackButton={false}>
        <StatusBar barStyle="light-content" />
        <VibeCheckForm closeModal={closeModal} />
      </FormLayout>
    </View>
  )
}

export default VibeCheckScreen

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, Button, HStack, Image, Text, VStack } from 'native-base'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import DefaultLayout from '../../layouts/DefaultLayout'
import { MemberScreenParams } from '../../screens/MemberScreen'
import VideoPlayer from '../explore/video/VideoPlayer.web'
import FixedContainer from '../_shared/layout/FixedContainer'
const Intro = require('../../assets/images/voyages/intro-video.png')

const VoyagesVideoIntro = () => {
  const isDesktop = useDesktopBreakpoint()
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()

  // Update when Voyages videos is ready
  const activity = {
    videoUrl: 'https://vimeo.com/903478011',
  }

  return (
    <DefaultLayout
      hasNavFooter={false}
      bg={!isDesktop ? 'white' : 'muted.50'}
      activeLabel="Voyage">
      {isDesktop ? (
        <FixedContainer width="1024px" size="md">
          <HStack justifyContent="space-between" alignItems="center">
            <Text
              color="primary.900"
              fontSize="24px"
              fontWeight={700}
              mt={4}
              mb={6}>
              Introduction
            </Text>
            <Button
              onPress={() => navigation.navigate('LandingVoyage')}
              color="primary.700"
              variant="subtle"
              bg="white"
              width="98px"
              height="36px">
              Skip
            </Button>
          </HStack>
          <Box
            alignItems="center"
            p={10}
            bg="white"
            borderColor={isDesktop ? 'grey.200' : 'transparent'}
            borderWidth={isDesktop ? 1 : 0}
            borderRadius={'20px'}
            mb={isDesktop ? 10 : 0}>
            <Text
              textAlign="center"
              fontSize="2xl"
              color="primary.900"
              fontWeight={500}>
              Embark on your path.
            </Text>
            <Text mt={2} fontSize="md" color="primary.900" textAlign="center">
              {`Watch our video introduction to Voyages.`}
            </Text>
            <Box width="462px" height="260px" my={6}>
              <VideoPlayer
                activity={activity}
                videoAspectRatioCallback={3 / 2}
              />
            </Box>
            <Button
              width="340px"
              onPress={() => navigation.navigate('LandingVoyage')}>
              Continue
            </Button>
          </Box>
        </FixedContainer>
      ) : (
        <VStack>
          <HStack
            width="100%"
            alignItems="flex-end"
            alignContent="flex-start"
            height={98}
            backgroundColor="white"
            borderBottomWidth={1}
            px={6}
            shadow={0.5}
            borderBottomColor="muted.50">
            <Text fontSize="xl" fontWeight={500} mb={2}>
              Introduction
            </Text>
          </HStack>
          <VStack p={4} alignItems="center">
            <Image
              alt="Ilustration"
              mt={isDesktop ? 0 : 2}
              mb={8}
              source={Intro}
              alignSelf="center"
              width={isDesktop ? '342px' : '283px'}
              height={isDesktop ? '240px' : '195px'}
            />
            <Text fontSize="2xl" color="primary.900" fontWeight={500}>
              Embark on your path.
            </Text>
            <Text mt={2} fontSize="md" color="primary.900" textAlign="center">
              Watch our video introduction to Voyages.
            </Text>
            <VideoPlayer activity={activity} videoAspectRatioCallback={7 / 4} />
            <Button
              width="100%"
              mt={8}
              mb={4}
              onPress={() => navigation.navigate('LandingVoyage')}>
              Continue
            </Button>
          </VStack>
        </VStack>
      )}
    </DefaultLayout>
  )
}

export default VoyagesVideoIntro

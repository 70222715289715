import { Badge, Box, Divider, HStack, Text, VStack } from 'native-base'
import { useEffect, useState } from 'react'
import useSound from 'use-sound'
import AttendeeRole from '../../../../domain/enums/AttendeeRole'
import AttendeeState from '../../../../domain/enums/AttendeeState'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import useRoomClientContext from '../../providers/RoomClientProvider'
import useRoomContext from '../../providers/RoomProvider'
import Card from '../_shared/card/Card'
import RosterItem from './RosterItem'
const chimeSound = require('../../assets/audio/chime-sound.mp3')

const Roster = ({}) => {
  /* State */
  const room = useRoomContext()
  const roomClient = useRoomClientContext()
  const isDesktop = useDesktopBreakpoint()
  const [play] = useSound(chimeSound)

  const [moderators, setModerators] = useState([])
  const [participants, setParticipants] = useState([])
  const [registrationCount, setRegistrationCount] = useState(0)
  const [prevParticipantsCount, setPrevParticipantsCount] = useState(0)

  /* Functions */
  const sortAttendee = (a, b) => {
    // Prioritize self first
    if (b.userId === room.me.userId) return 1
    else if (a.userId === room.me.userId) return -1
    // Prioritize active speaker second, newest to oldest
    else return b.microphoneOn && b.activeSpeaker ? 1 : -1
  }

  useEffect(() => {
    if (
      room.me.role === AttendeeRole.Moderator &&
      participants.length > prevParticipantsCount
    ) {
      play()
    }
  }, [participants])

  /* Hooks */
  useEffect(() => {
    const attendees = room.attendees
    if (attendees) {
      setModerators(
        attendees
          .filter((a) => a.role === AttendeeRole.Moderator)
          .sort(sortAttendee)
      )

      setParticipants(
        attendees
          .filter(
            (a) =>
              (a.role === AttendeeRole.Participant ||
                a.role === AttendeeRole.Spectator) &&
              (a.state === AttendeeState.Waiting ||
                a.state === AttendeeState.Joined)
          )
          .sort(sortAttendee)
      )

      setRegistrationCount(
        attendees.filter(
          (a) =>
            a.role === AttendeeRole.Participant ||
            a.role === AttendeeRole.Spectator
        ).length
      )
    }
    setPrevParticipantsCount(participants.length)
  }, [room.attendees])

  const BottomComponent = () => (
    <HStack alignItems="center" w="100%">
      <Badge bg="muted.500" borderRadius="full" minW="24px">
        <Text fontSize="xs" color="text.50" bold>
          {registrationCount}
        </Text>
      </Badge>
      <Text ml="2">registered</Text>
    </HStack>
  )

  return (
    <Card
      boxProps={{
        w: !isDesktop ? '100%' : '322px',
        h: '100%',
        borderWidth: !isDesktop ? '0' : '1px',
      }}
      title={isDesktop && 'People'}
      handleClose={roomClient.toggleRoster}
      body={
        <VStack>
          {moderators.map((moderator, index) => (
            <Box key={index}>
              <RosterItem attendee={moderator} />
            </Box>
          ))}
          {participants.length > 0 && <Divider my="2" />}
          {participants.map((participant, index) => (
            <Box key={index} my="2">
              <RosterItem attendee={participant} />
            </Box>
          ))}
        </VStack>
      }
      actions={
        !isDesktop || room.me.role !== AttendeeRole.Moderator ? null : (
          <BottomComponent />
        )
      }
    />
  )
}

export default Roster

import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import BalancedSelfDesktop from '../../components/voyages/landingVoyage/BalancedSelfDesktop'
import BalancedSelfMobile from '../../components/voyages/landingVoyage/BalancedSelfMobile'
import FixedContainer from '../../components/_shared/layout/FixedContainer'
import DefaultLayout from '../../layouts/DefaultLayout'

const BalancedSelfScreen = () => {
  const isDesktop = useDesktopBreakpoint()

  return (
    <DefaultLayout
      scrollView={isDesktop ? true : false}
      activeLabel="Voyage"
      hasNavFooter={false}
      bg={isDesktop ? 'muted.50' : 'white'}>
      {!isDesktop ? (
        <BalancedSelfMobile />
      ) : (
        <FixedContainer size="lg">
          <BalancedSelfDesktop />
        </FixedContainer>
      )}
    </DefaultLayout>
  )
}

export default BalancedSelfScreen

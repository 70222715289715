import * as Calendar from 'expo-calendar'
import { Alert, Linking } from 'react-native'
import * as RNLocalize from 'react-native-localize'
import Configuration from '../../../domain/services/ConfigurationService'

const useCalendar = (activityId, onSuccessCallback) => {
  const prefix = Configuration.SCHEME
  return {
    addEvent: (title, startDate, endDate) => {
      ;(async () => {
        const { status: prevCalendarPermissionStatus } =
          await Calendar.getCalendarPermissionsAsync()
        const { status: prevRemindersPermissionStatus } =
          await Calendar.getRemindersPermissionsAsync()

        const calendarRequestRes =
          await Calendar.requestCalendarPermissionsAsync()
        const calendarStatus = calendarRequestRes.status
        const reminderRequestRes =
          await Calendar.requestRemindersPermissionsAsync()
        const reminderStatus = reminderRequestRes.status
        if (calendarStatus === 'granted' && reminderStatus === 'granted') {
          const calendars = await Calendar.getCalendarsAsync(
            Calendar.EntityTypes.EVENT
          )
          let calendarId = -1
          const cabanaCalendar = calendars.find(
            (calendar) => calendar.title === 'Cabana'
          )
          if (!cabanaCalendar) calendarId = await createCalendar()
          else calendarId = cabanaCalendar.id
          const newEventId = await Calendar.createEventAsync(calendarId, {
            title: title,
            startDate: startDate,
            endDate: endDate,
            timeZone: RNLocalize.getTimeZone(),
            alarms: [
              {
                relativeOffset: -15,
              },
            ],
            notes: `Join: ${prefix}://activity?activityId=` + activityId,
          })
          onSuccessCallback()
        } else if (
          prevCalendarPermissionStatus !== 'undetermined' &&
          prevCalendarPermissionStatus !== 'granted'
        ) {
          Alert.alert(
            'This feature requires calendar access',
            'In iPhone settings, allow calendar access for Cabana',
            [
              {
                text: 'Not now',
                style: 'cancel',
              },
              {
                text: 'Open Settings',
                onPress: () => {
                  Linking.openURL('app-settings:')
                },
              },
            ]
          )
        } else if (
          prevRemindersPermissionStatus !== 'undetermined' &&
          prevRemindersPermissionStatus !== 'granted'
        ) {
          Alert.alert(
            'This feature requires reminders access',
            'In iPhone settings, allow reminders access for Cabana',
            [
              {
                text: 'Not now',
                style: 'cancel',
              },
              {
                text: 'Open Settings',
                onPress: () => {
                  Linking.openURL('app-settings:')
                },
              },
            ]
          )
        }
      })()
    },
  }
}
const createCalendar = async () => {
  const defaultCalendarSource =
    Platform.OS === 'ios'
      ? await (
          await Calendar.getDefaultCalendarAsync()
        ).source
      : { isLocalAccount: true, name: 'Expo Calendar' }
  const newCalendarID = await Calendar.createCalendarAsync({
    title: 'Cabana',
    color: 'blue',
    entityType: Calendar.EntityTypes.EVENT,
    sourceId: defaultCalendarSource.id,
    source: defaultCalendarSource,
    name: 'cabanaCalendar',
    ownerAccount: 'personal',
    accessLevel: Calendar.CalendarAccessLevel.OWNER,
  })
  return newCalendarID
}

export default useCalendar

import { NavigationContainer, useLinkTo } from '@react-navigation/native'
import { capitalCase } from 'capital-case'
import { useState } from 'react'
import { Linking } from 'react-native'
import clevertap from '../../../domain/services/clevertap/ClevertapAdapter'
import Configuration from '../../../domain/services/ConfigurationService'
import { navigationRef } from '../../../domain/services/RootNavigation'
import IntercomWrapper from '../components/IntercomWrapper'
import SuccessModalWrapper from '../components/SuccessModalWrapper'
import useAuthentication from '../hooks/authentication/useAuthentication'
import { guestLinks, GuestScreen } from './GuestScreen'
import { memberLinks, MemberScreen } from './MemberScreen'

// for handling clicks on notifications when the application is running (backgrounded)
const CleverTapNotificationClickHandler = ({}) => {
  const prefix = Configuration.SCHEME + '://'
  const linkTo = useLinkTo()
  clevertap.onPushNotificationClicked((deepLink) => {
    const url = deepLink?.replace(prefix, '/')
    linkTo(url)
  })
  return <></>
}

const NavigationScreen = ({}) => {
  const { user, isLoading } = useAuthentication()

  const [routeName, setRouteName] = useState(undefined)
  const [routeParams, setRouteParams] = useState(undefined)

  const linking = {
    prefixes: [`${Configuration.SCHEME}://`],
    config: {
      screens: {
        ...memberLinks,
        ...guestLinks,
      },
    },
    getInitialURL: () => {
      return clevertap.getInitialURL().catch(() => Linking.getInitialURL())
    },
  }

  if (isLoading) return

  return (
    <NavigationContainer
      documentTitle={{
        formatter: (options, route) => {
          return `Cabana - ${capitalCase(options?.title ?? route?.name)}`
        },
      }}
      linking={linking}
      onReady={() => {
        setRouteName(navigationRef.getCurrentRoute().name)
      }}
      onStateChange={() => {
        const { name: currentRouteName, params: currentRouteParams } =
          navigationRef.getCurrentRoute()
        setRouteName(currentRouteName)
        setRouteParams(currentRouteParams)
      }}
      ref={navigationRef}>
      <CleverTapNotificationClickHandler />
      <IntercomWrapper routeName={routeName}>
        <SuccessModalWrapper routeParams={routeParams}>
          {user ? <MemberScreen /> : <GuestScreen />}
        </SuccessModalWrapper>
      </IntercomWrapper>
    </NavigationContainer>
  )
}

export default NavigationScreen

import { useNavigation } from '@react-navigation/native'
import { Flex, Heading, Image, StatusBar, Text, VStack } from 'native-base'
import { useEffect } from 'react'
import { Animated, Easing, StyleSheet, useWindowDimensions } from 'react-native'

const waves = require('../../assets/images/oasis-animation/waves.png')
const forest = require('../../assets/images/oasis-animation/forest.png')
const beach = require('../../assets/images/oasis-animation/beach.png')
const lake = require('../../assets/images/oasis-animation/lake.png')
const cabana = require('../../assets/images/oasis-animation/cabana.png')

const animated = new Animated.Value(0)
const beachInputRange = [0, 1]
const beachOutputRange = ['0deg', '-90deg']
const beachRotate = animated.interpolate({
  inputRange: beachInputRange,
  outputRange: beachOutputRange,
})
const lakeInputRange = [0, 1, 2]
const lakeOutputRange = ['90deg', '0deg', '-90deg']
const lakeRotate = animated.interpolate({
  inputRange: lakeInputRange,
  outputRange: lakeOutputRange,
})
const forestInputRange = [0, 1, 2, 3]
const forestOutputRange = ['90deg', '90deg', '0deg', '-90deg']
const forestRotate = animated.interpolate({
  inputRange: forestInputRange,
  outputRange: forestOutputRange,
})
const cabanaInputRange = [0, 1, 2, 3]
const cabanaOutputRange = ['90deg', '90deg', '90deg', '0deg']
const cabanaRotate = animated.interpolate({
  inputRange: cabanaInputRange,
  outputRange: cabanaOutputRange,
})

const animate = (finishCallback) => {
  Animated.sequence([
    Animated.delay(500),
    Animated.timing(animated, {
      toValue: 1,
      duration: 1500,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.ease),
    }),
    Animated.delay(500),
    Animated.timing(animated, {
      toValue: 2,
      duration: 1500,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.ease),
    }),
    Animated.delay(500),
    Animated.timing(animated, {
      toValue: 3,
      duration: 1500,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.ease),
    }),
  ]).start(() => {
    finishCallback()
  })
}

const CreatingOasisScreen = () => {
  const { width } = useWindowDimensions()
  const navigation = useNavigation()

  useEffect(() => {
    animated.setValue(0)
    animate(() => {
      navigation.navigate('Home')
    })
  }, [])

  const beachTransform = [{ rotate: beachRotate }]
  const lakeTransform = [{ rotate: lakeRotate }]
  const forestTransform = [{ rotate: forestRotate }]
  const cabanaTransform = [{ rotate: cabanaRotate }]

  return (
    <VStack h="100%" w="100%" background="white">
      <StatusBar barStyle="dark-content" />
      <Animated.View style={[styles.item, { transform: beachTransform }]}>
        <Image source={beach} alt="Oasis" alignSelf="center" />
      </Animated.View>
      <Animated.View style={[styles.item, { transform: lakeTransform }]}>
        <Image source={lake} alt="Oasis" alignSelf="center" />
      </Animated.View>
      <Animated.View style={[styles.item, { transform: forestTransform }]}>
        <Image source={forest} alt="Oasis" alignSelf="center" />
      </Animated.View>
      <Animated.View style={[styles.item, { transform: cabanaTransform }]}>
        <Image source={cabana} alt="Oasis" alignSelf="center" />
      </Animated.View>
      <Flex flex="1" justify="flex-end">
        <Image source={waves} alt="Oasis" position={'absolute'} width={width} />
        <Heading
          alignSelf="center"
          mb="28"
          fontWeight="bold"
          fontSize="24"
          color="#FDFDFD">
          Get ready for your oasis
        </Heading>
        <Text alignSelf="center" mb="131" fontSize="16" top={0} color="#FDFDFD">
          We're building your Cabana
        </Text>
      </Flex>
    </VStack>
  )
}

export default CreatingOasisScreen

const styles = StyleSheet.create({
  item: {
    position: 'absolute',
    width: 210,
    top: 170,
    left: 95,
    height: 1000,
  },
})

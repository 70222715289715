import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { useQuery } from '@tanstack/react-query'
import {
  Box,
  Button,
  Divider,
  FlatList,
  HStack,
  ScrollView,
  Text,
  VStack,
} from 'native-base'
import { useEffect, useState } from 'react'
import analytics from '../../../../domain/services/analytics/AnalyticsService'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import DefaultLayout from '../../layouts/DefaultLayout'
import { MemberScreenParams } from '../../screens/MemberScreen'
import FixedContainer from '../_shared/layout/FixedContainer'
import VoyageSingleResult from './VoyageSingleResult'

const VoyagesScreenerResults = () => {
  const isDesktop = useDesktopBreakpoint()
  const { data } = useQuery(['focusAreas'])
  const [focusAreas, setFocusAreas] = useState([])
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()

  useEffect(() => {
    if (!data || data.screener !== 'COMPLETE') return
    const groups = data.focusGroups
    const areas = groups.map((group) => group.focusAreas).flat()
    areas.sort((a, b) => a.recommendedOrder - b.recommendedOrder)
    areas.splice(3)
    setFocusAreas(areas)

    if (data) {
      analytics.track('Screener Completed', {
        focusArea1: areas[0].name,
        focusArea2: areas[1].name,
        focusArea3: areas[2].name,
      })
    }
  }, [data])

  return (
    <DefaultLayout
      hasNavFooter={false}
      bg={!isDesktop ? 'white' : 'muted.50'}
      activeLabel="Voyage">
      {isDesktop ? (
        <FixedContainer width="1024px" size="md">
          <Text
            color="primary.900"
            fontSize="24px"
            fontWeight={700}
            mt={4}
            mb={6}>
            Results
          </Text>
          <Box
            p={10}
            bg="white"
            borderColor={isDesktop ? 'grey.200' : 'transparent'}
            borderWidth={isDesktop ? 1 : 0}
            borderRadius={'20px'}
            mb={isDesktop ? 10 : 0}>
            <>
              <Text fontSize="2xl" color="primary.900" fontWeight={500}>
                Getting started
              </Text>
              <Text
                fontSize={isDesktop ? 'sm' : 'xs'}
                color="primary.900"
                mt={4}
                mb={6}>
                Begin your journey towards mental wellness with our curated
                focus areas designed just for you, each step leading you to
                inner peace, self-compassion, and a deeper understanding of
                yourself.
              </Text>
              <FlatList
                contentContainerStyle={{
                  flex: 1,
                  justifyContent: 'space-between',
                }}
                data={focusAreas}
                horizontal
                ItemSeparatorComponent={() => (
                  <Divider thickness="1" mx="4" orientation="vertical" />
                )}
                renderItem={({ item, index }) => (
                  <VoyageSingleResult focusArea={item} />
                )}
                keyExtractor={(item) => item.id}
              />
            </>
            <VStack mt={6} alignSelf="center">
              <Button
                onPress={() => navigation.navigate('VoyageIntro')}
                width="358px">
                Continue
              </Button>
            </VStack>
          </Box>
        </FixedContainer>
      ) : (
        <VStack>
          <HStack
            width="100%"
            alignItems="flex-end"
            alignContent="flex-start"
            height={98}
            backgroundColor="white"
            borderBottomWidth={1}
            px={6}
            shadow={0.5}
            borderBottomColor="muted.50">
            <Text fontSize="xl" fontWeight={500} mb={2}>
              Results
            </Text>
          </HStack>
          <ScrollView p={4}>
            <Text fontSize="2xl" color="primary.900" fontWeight={500}>
              Getting started
            </Text>
            <Text fontSize="xs" color="primary.900" mt={4} mb={6}>
              Begin your journey towards mental wellness with our curated focus
              areas designed just for you, each step leading you to inner peace,
              self-compassion, and a deeper understanding of yourself.
            </Text>
            <FlatList
              data={focusAreas}
              renderItem={({ item, index }) => (
                <VoyageSingleResult focusArea={item} />
              )}
              keyExtractor={(item) => item.id}
            />
          </ScrollView>
          <VStack p={4}>
            <Button onPress={() => navigation.navigate('VoyageIntro')}>
              Continue
            </Button>
          </VStack>
        </VStack>
      )}
    </DefaultLayout>
  )
}

export default VoyagesScreenerResults

import { createNativeStackNavigator } from '@react-navigation/native-stack'
import LoginCallbackScreen from './login/LoginCallbackScreen'
import OchsnerScreen from './login/OchsnerScreen'
import OchsnerSignupScreen from './login/OchsnerSignupScreen'
import LandingScreen from './onboarding/LandingScreen'
import SignupScreen from './onboarding/SignupScreen'
import VerifyAccessCodeScreen from './onboarding/VerifyAccessCodeScreen'
import VerifyEligibilityScreen from './onboarding/VerifyEligibilityScreen'

export type GuestScreenParams = {
  Landing: undefined
  Login: { email?: string }
  LoginCallback: undefined
  Ochsner: undefined
  Signup: { email: string; referralCode?: string }
  VerifyEligibility: { email: string }
  VerifyAccessCode: undefined
  OchsnerSignup: undefined
  AccountCreated: { userEmail: string }
  CreateAccount: { email: string; referralCode?: string }
}

const GuestScreen = () => {
  const Stack = createNativeStackNavigator<GuestScreenParams>()

  return (
    <Stack.Navigator
      initialRouteName="Landing"
      screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Landing" component={LandingScreen} />
      <Stack.Screen name="Ochsner" component={OchsnerScreen} />
      <Stack.Screen name="Signup" component={SignupScreen} />
      <Stack.Screen name="CreateAccount" component={SignupScreen} />

      <Stack.Screen
        name="VerifyEligibility"
        component={VerifyEligibilityScreen}
      />
      <Stack.Screen
        name="VerifyAccessCode"
        component={VerifyAccessCodeScreen}
      />
      <Stack.Screen name="LoginCallback" component={LoginCallbackScreen} />
      <Stack.Screen name="OchsnerSignup" component={OchsnerSignupScreen} />
    </Stack.Navigator>
  )
}

const guestLinks = {
  Landing: '/landing',
  Login: '/login',
  LoginCallback: '/login-callback',
  Ochsner: '/ochsner',
  Signup: '/sign-up',
  CreateAccount: '/create-account',
  VerifyEligibility: '/verify-eligibility',
  OchsnerSignup: '/create-organization-account',
}

export { GuestScreen, guestLinks }

import { useNavigation } from '@react-navigation/native'
import { Flex, Heading, HStack, Spacer, Text, VStack } from 'native-base'
import { useState } from 'react'
import { Pressable, useWindowDimensions } from 'react-native'
import { useUserProfileContext } from '../../providers/UserProfileProvider'
import ActivityModalPreview from '../explore/activities/ActivityModalPreview'
import CardRow from '../_shared/card/CardRow'
import Checklist from './dailyRecs/Checklist'
import InspirationalQuote from './InspirationalQuote'

const HomeMobileView = ({
  upcomingGroups,
  checklistItems,
  checklistConditions,
}) => {
  const navigation = useNavigation()
  const { userProfile } = useUserProfileContext()
  const { width } = useWindowDimensions()

  const [modalLiveGroupData, setModalLiveGroupData] = useState(null)
  const [modalLiveGroupShown, setModalLiveGroupShown] = useState(false)
  const handleCloseModal = () => setModalLiveGroupShown(false)

  let goalHeading = 'Daily boost'
  if (userProfile?.goal === 'REDUCE_STRESS') {
    goalHeading = 'Daily stress relief'
  } else if (userProfile?.goal === 'BUILD_SKILLS') {
    goalHeading = 'Daily skill builder'
  }

  const onPressLiveGroup = (activity) => {
    setModalLiveGroupData(activity)
    setModalLiveGroupShown(true)
  }

  return (
    <Flex flex="1" bg="white" borderRadius="2xl" mt="200px" px="16px">
      <VStack justifyContent="center" pt="24px">
        {!!upcomingGroups?.length && (
          <>
            <HStack alignItems="center">
              <Heading size="md">Upcoming groups</Heading>
              <Spacer />
              <Pressable onPress={() => navigation.navigate('LiveGroups')}>
                <Text fontSize="16px">see all</Text>
              </Pressable>
            </HStack>
            <CardRow
              width={width - 32}
              onPressLiveGroup={onPressLiveGroup}
              data={upcomingGroups}
            />
          </>
        )}
        <Heading
          size="md"
          mt={!!upcomingGroups?.length ? '6' : undefined}
          mb="3">
          {goalHeading}
        </Heading>
        <Checklist
          items={checklistItems}
          itemConditions={checklistConditions}
        />
        <InspirationalQuote />
      </VStack>
      <ActivityModalPreview
        modalData={modalLiveGroupData}
        modalShown={modalLiveGroupShown}
        handleCloseModal={handleCloseModal}
        bottomSheetStyling={{
          hasDivider: false,
          width: 400,
          top: null,
          hasCloseOnBottom: false,
        }}
      />
    </Flex>
  )
}

export default HomeMobileView

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, Button, Image, Text } from 'native-base'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import { MemberScreenParams } from '../../../screens/MemberScreen'

const Img = require('../../../assets/images/voyages/finished.png')

const FinishedVoyagesActivities = () => {
  const isDesktop = useDesktopBreakpoint()
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()

  return (
    <Box
      mb={4}
      borderRadius="20px"
      bg="white"
      p={4}
      borderColor="grey.200"
      borderWidth={1}>
      <Image
        alt="Ilustration"
        source={Img}
        alignSelf="center"
        width={isDesktop ? '272px' : '263px'}
        height={isDesktop ? '267px' : '195px'}
      />
      <Text
        fontSize="32px"
        color="primary.900"
        fontWeight={700}
        textAlign="center"
        mt={isDesktop ? -5 : 0}
        mb={5}>
        Congrats
      </Text>
      <Text
        width={isDesktop ? '436px' : '100%'}
        mb={5}
        fontSize="16px"
        color="primary.900"
        textAlign="center"
        alignSelf="center">
        You have completed all Voyage activities to date. We’ll be adding new
        paths and activities soon.
      </Text>
      <Button
        width={isDesktop ? '342px' : '100%'}
        alignSelf="center"
        mt={4}
        onPress={() => navigation.navigate('LandingVoyage')}>
        <Text color="white" fontSize="16px" fontWeight={500}>
          Keep exploring
        </Text>
      </Button>
    </Box>
  )
}

export default FinishedVoyagesActivities

import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { format } from 'date-fns'
import { Text, View, VStack } from 'native-base'
import { useState } from 'react'
import Modal from 'react-native-modal'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import { MemberScreenParams } from '../../screens/MemberScreen'
import VibeCheckScreen from '../../screens/VibeCheckScreen'
import EmptyListComponent from '../EmptyListComponent'
import { SkeletonLoader } from '../_shared/SkeletonLoader'
import { useGetMinutesHistory } from './queries'
import WeeklyProgress from './WeeklyProgress'

const EmptyImage = require('../../assets/images/vibe-check/empty-weekly-insights.png')

interface Week {
  start: string
  end: string
  minutes: number
}

interface Data {
  month: string
  weeks: Week[]
}

const noWeeklyInsights = (data) => {
  return (
    data &&
    data?.months
      .map((month) => month?.weeks.every((week) => week.minutes === 0))
      .every(Boolean)
  )
}

const WeeklyInsightsContainer = () => {
  const { data, isLoading } = useGetMinutesHistory()
  const monthlyData = data as { months: Data[] }
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  const isDesktop = useDesktopBreakpoint()

  const [isModalVisible, setModalVisible] = useState(false)

  const toggleModal = () => {
    setModalVisible(!isModalVisible)
  }

  const handleEmptyStatePress = () => {
    if (isDesktop) {
      toggleModal()
    } else {
      navigation.navigate('VibeCheck')
    }
  }

  return (
    <VStack mb={isDesktop ? 0 : 10}>
      {!noWeeklyInsights(data) &&
        (isLoading ? (
          <VStack padding={4} bg="white" borderRadius="20px">
            <SkeletonLoader size={{ width: '100%', height: 200 }} />
          </VStack>
        ) : monthlyData ? (
          monthlyData?.months.map((month) => (
            <View
              key={month.month}
              borderColor="grey.200"
              borderWidth={isDesktop ? 1 : 0}
              backgroundColor="white"
              px={4}
              py={5}
              mb={5}
              borderRadius="20px">
              <Text color="primary.700" fontWeight={700} mb={6}>
                {format(new Date(month.month), 'MMMM yyyy')}
              </Text>
              {month.weeks.map((week) => (
                <WeeklyProgress
                  key={week.start}
                  startDate={week.start}
                  endDate={week.end}
                  minutes={week.minutes}
                />
              ))}
            </View>
          ))
        ) : (
          <View
            borderColor="grey.200"
            borderWidth={1}
            backgroundColor="white"
            px={4}
            py={5}
            mb={4}
            borderRadius="20px">
            <EmptyListComponent
              description="Check in with your emotions and start building your self-awareness"
              buttonText="Complete a vibe check"
              buttonAction={() => handleEmptyStatePress()}
              imageSource={EmptyImage}
            />
          </View>
        ))}
      <Modal isVisible={isModalVisible}>
        <VibeCheckScreen />
      </Modal>
    </VStack>
  )
}

export default WeeklyInsightsContainer

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  Content,
  Group,
} from '../../../domain/models/interfaces/activityInterfaces'
import useInvalidateActivityQueriesInTimestamp from '../hooks/useInvalidateActivityQueriesInTimestamp'
import useParseActivity from '../hooks/useParseActivity'
import { useCabanaApi } from '../providers/CabanaApiProvider'

export const useGetRecentlyAdded = (): {
  recentlyAdded: [Group | Content]
  isLoading: boolean
} => {
  const { ActivityCardApi } = useCabanaApi()
  const { data, isLoading } = useQuery({
    queryKey: ['Activity', 'Recently', 'added'],
    queryFn: () => ActivityCardApi.recentlyAdded(),
    staleTime: 5 * 60 * 1000,
  })
  return { recentlyAdded: data, isLoading }
}

export const useGetLiveAndUpcoming = (): {
  liveAndUpcoming: [Group | Content]
  isLoading: boolean
} => {
  const { ActivityCardApi } = useCabanaApi()
  const { data, isLoading } = useQuery({
    queryKey: ['Activity', 'Live', 'upcoming'],
    queryFn: () => ActivityCardApi.liveAndUpcoming(),
  })

  useInvalidateActivityQueriesInTimestamp(data)
  return {
    liveAndUpcoming: data,
    isLoading,
  }
}

export const useForYouGroups = (): {
  registeredGroups: [Group]
  isLoading: boolean
} => {
  const { ActivityCardApi } = useCabanaApi()
  const { data, isLoading } = useQuery({
    queryKey: ['Activity', 'ForYou'],
    queryFn: () => ActivityCardApi.getForYouGroups(),
  })
  useInvalidateActivityQueriesInTimestamp(data)
  return {
    registeredGroups: data,
    isLoading,
  }
}

export const useMyGroups = (): {
  registeredGroups: [Group]
  isLoading: boolean
} => {
  const { ActivityCardApi } = useCabanaApi()
  const { data, isLoading } = useQuery({
    queryKey: ['Activity', 'MyGroups'],
    queryFn: () => ActivityCardApi.getMyGroups(),
  })
  useInvalidateActivityQueriesInTimestamp(data)
  return {
    registeredGroups: data,
    isLoading,
  }
}

const useRegisterForGroup = (activity) => {
  const { ActivityApi } = useCabanaApi()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation(ActivityApi.register, {
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['Activity'] })
    },
  })
  return { register: mutate, isLoading }
}

const useUnregisterForGroup = (activity) => {
  const { ActivityApi } = useCabanaApi()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(ActivityApi.unregister, {
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['Activity'] })
    },
  })
  return { unregister: mutate, isLoading }
}

export const useRegistration = (activity) => {
  const { register, isLoading: rIsLoading } = useRegisterForGroup(activity)
  const { unregister, isLoading: uIsLoading } = useUnregisterForGroup(activity)
  return { register, unregister, isLoading: rIsLoading || uIsLoading }
}

const getActivityDetails = async ({ queryKey }) => {
  const [_, _2, searchParam, ActivityDetailsApi] = queryKey
  return await ActivityDetailsApi.details(searchParam)
}

export const useGetActivityDetails = (uuid) => {
  const { ActivityDetailsApi } = useCabanaApi()

  const { data, isLoading } = useQuery({
    queryKey: ['Activity', 'Details', uuid, ActivityDetailsApi],
    queryFn: ({ queryKey }) => getActivityDetails({ queryKey }),
  })

  const activity = useParseActivity(data)
  useInvalidateActivityQueriesInTimestamp(activity ? [activity] : undefined)
  return { activity, isLoading }
}

export const useBookmark = (activityId?: string) => {
  const { ActivityApi } = useCabanaApi()
  const queryClient = useQueryClient()

  const { data: bookmarks, isLoading } = useQuery({
    queryKey: ['Bookmarks'],
    queryFn: () => ActivityApi.getBookmarks(),
  })

  const bookmarkMutation = useMutation(() => ActivityApi.bookmark(activityId), {
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['Activity'] })
    },
  })

  const unbookmarkMutation = useMutation(
    (id?: string) => ActivityApi.unbookmark(activityId || id),
    {
      onSettled: () =>
        Promise.all([
          queryClient.invalidateQueries(['Activity']),
          queryClient.invalidateQueries(['Bookmarks']),
        ]),
    }
  )

  return {
    bookmarks,
    isLoading,
    bookmark: bookmarkMutation.mutate,
    unbookmark: unbookmarkMutation.mutate,
  }
}

export const useLike = (activityId?: string) => {
  const { ActivityApi } = useCabanaApi()
  const queryClient = useQueryClient()

  const likeMutation = useMutation(() => ActivityApi.like(activityId), {
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['Activity'] })
    },
  })

  const unlikeMutation = useMutation(() => ActivityApi.unlike(activityId), {
    onSettled: () =>
      Promise.all([
        queryClient.invalidateQueries(['Activity']),
        queryClient.invalidateQueries(['Likes']),
      ]),
  })

  return {
    like: likeMutation.mutate,
    unlike: unlikeMutation.mutate,
  }
}

import { Button, Text } from 'native-base'
import { useEffect, useState } from 'react'
import { useCabanaApi } from '../../providers/CabanaApiProvider'
import useRoomClientContext from '../../providers/RoomClientProvider'
import useRoomContext from '../../providers/RoomProvider'
import FaIcon from '../_shared/FaIcon'

const StartRoom = ({}) => {
  const { RoomApi } = useCabanaApi()
  const room = useRoomContext()
  const roomClient = useRoomClientContext()
  const [isActive, setActive] = useState(false)
  const [startButtonEnabled, setStartButtonEnabled] = useState(false)

  useEffect(() => {
    setActive(room.active)
    setStartButtonEnabled(room.active)

    let interval = null

    if (!room.active) {
      let startThreshold = new Date(room.startsAt)
      startThreshold.setMinutes(startThreshold.getMinutes() - 15)

      interval = setInterval(() => {
        let readyToStart = startThreshold < new Date()
        setStartButtonEnabled(readyToStart)
        if (readyToStart) {
          clearInterval(interval)
          interval = null
        }
      }, 3000)
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [room])

  const toggleIsActive = () => {
    if (!isActive) RoomApi.start(room.roomId)
    else roomClient.toggleEndGroup()
  }
  return (
    <Button
      backgroundColor={
        isActive
          ? 'error.500'
          : startButtonEnabled
          ? 'secondary.500'
          : 'muted.400'
      }
      borderRadius="full"
      leftIcon={
        <FaIcon
          name={isActive ? 'arrow-right-from-bracket' : 'circle-play'}
          size={20}
          color={startButtonEnabled ? 'text.50' : 'text.700'}
        />
      }
      onPress={toggleIsActive}
      disabled={!startButtonEnabled}>
      <Text color={startButtonEnabled ? 'text.50' : 'text.700'}>
        {isActive ? 'Stop' : 'Start'} group
      </Text>
    </Button>
  )
}

export default StartRoom

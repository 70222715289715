import AccessCodeForm from '../../components/onboarding/AccessCodeForm'
import FormLayout from '../../layouts/FormLayout'
import BackgroundLayout from './BackgroundLayout'

const VerifyAccessCodeScreen = ({ route }) => {
  const code = route?.params?.code
  const codeEmailed = route?.params?.codeEmailed === 'true'

  return (
    <BackgroundLayout>
      <FormLayout bg="white" autoBackButton={false}>
        <AccessCodeForm code={code} codeEmailed={codeEmailed} />
      </FormLayout>
    </BackgroundLayout>
  )
}

export default VerifyAccessCodeScreen

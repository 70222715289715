import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { format, parseISO } from 'date-fns'
import { Divider, Flex, Pressable, SectionList, Text } from 'native-base'
import { useCallback } from 'react'
import { useWindowDimensions } from 'react-native'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import EmptyListComponent from '../../components/EmptyListComponent'
import LatestVibeCheck from '../../components/profile/LatestVibeCheck'
import WeeklyVibeCheck from '../../components/profile/WeeklyVibeCheck'
import moods from '../../components/vibeCheck/Moods'
import { useVibeCheckHistory } from '../../components/vibeCheck/queries'
import FaIcon from '../../components/_shared/FaIcon'
import AbstractCard from '../../components/_shared/layout/AbstractCard'
import ProfileContainer from '../../components/_shared/layout/ProfileContainer'
import { MemberScreenParams } from '../MemberScreen'

const EmptyImage = require('../../assets/images/vibe-check/empty-vibe-check.png')

const groupDataByMonth = (data) => {
  const groupedData = []

  data.forEach((obj) => {
    const date = parseISO(obj.completedAt)
    const monthYear = format(date, 'MMMM yyyy')
    const monthData = groupedData.find((entry) => entry.month === monthYear)

    if (monthData) {
      monthData.data.push(obj)
    } else {
      groupedData.push({ month: monthYear, data: [obj] })
    }
  })

  return groupedData
}

const VibeCheckHistoryScreen = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  const isDesktop = useDesktopBreakpoint()
  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useVibeCheckHistory()
  const allItems =
    data?.pages.reduce((acc, val) => acc.concat(val.content), []) || []
  const parsedData = groupDataByMonth(allItems)
  const goToReview = useCallback(
    (item) =>
      navigation.navigate('VibeReview', {
        picked: item.mood,
        comment: item.note,
        date: item.completedAt,
      }),
    []
  )

  const renderItem = useCallback(({ item }) => {
    const mood = moods.find((x) => x.name === item.mood)
    return (
      <Pressable onPress={() => goToReview(item)} my={2}>
        <AbstractCard
          hasShadow={false}
          type="history"
          title={item.note}
          description={format(
            new Date(item.completedAt),
            'EEE, MMM d • h:mm a'
          )}
          leftComponent={
            <Flex
              w="56px"
              h="56px"
              borderRadius="lg"
              bg={mood.bgColor}
              px="1"
              pb="1">
              <FaIcon
                name={mood.icon}
                size={30}
                color={mood.color}
                props={{
                  m: 'auto',
                }}
              />
            </Flex>
          }
        />
      </Pressable>
    )
  }, [])

  const { height } = useWindowDimensions()
  const flatListHeight = height - height * 0.1

  return (
    <ProfileContainer title="Vibe check history" headerText="Vibe Check">
      {isDesktop && (
        <>
          <LatestVibeCheck />
          {allItems.length !== 0 && (
            <Text
              mt={6}
              ml={isDesktop ? 0 : 5}
              color="primary.900"
              fontWeight={700}
              fontSize="18px">
              Vibe check history
            </Text>
          )}
        </>
      )}

      {!isLoading && (
        <SectionList
          stickySectionHeadersEnabled={false}
          sections={parsedData}
          px={isDesktop ? 0 : 5}
          showsVerticalScrollIndicator={false}
          renderSectionHeader={({ section }) => {
            return (
              <>
                <Divider color="muted.300" />
                <Text fontWeight="700" mt={4} color="primary.900">
                  {section.month}
                </Text>
              </>
            )
          }}
          maxH={flatListHeight}
          renderItem={renderItem}
          pb="20px"
          onEndReached={(data) => {
            if (hasNextPage && !isFetchingNextPage) {
              fetchNextPage()
            }
          }}
          onEndReachedThreshold={0}
          ListEmptyComponent={
            !isDesktop && (
              <>
                <Text
                  color={'primary.900'}
                  fontWeight={700}
                  fontSize={18}
                  pt={4}>
                  Latest vibe check
                </Text>
                <EmptyListComponent
                  description="Check in with your emotions and start building your self-awareness"
                  buttonText="Complete a vibe check"
                  buttonAction={() => navigation.navigate('VibeCheck')}
                  imageSource={EmptyImage}
                />
                <Divider color="muted.300" mt={8} p={0.25} />
                <WeeklyVibeCheck />
              </>
            )
          }
        />
      )}
    </ProfileContainer>
  )
}

export default VibeCheckHistoryScreen

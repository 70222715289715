import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, Button, HStack, Text, VStack } from 'native-base'
import analytics from '../../../../domain/services/analytics/AnalyticsService'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import { maxStringLength } from '../../../helpers/maxStringLength'
import { MemberScreenParams } from '../../screens/MemberScreen'
import FaIcon from '../_shared/FaIcon'

const AccordionItem = ({
  step,
  color,
  index,
  isAccordionOpen,
  handleNavigation,
  currentStep,
  pathDetails,
  focusArea,
}) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  const isDesktop = useDesktopBreakpoint()

  return (
    <VStack>
      <HStack
        borderRadius="10px"
        mx={3}
        mb={2}
        px={3}
        alignItems="center"
        height="48px"
        justifyContent="space-between"
        borderColor={isAccordionOpen ? 'muted.300' : 'transparent'}
        borderWidth={1}>
        <HStack justifyContent="center" alignItems="center">
          <HStack
            justifyContent="center"
            alignItems="center"
            borderColor={currentStep ? 'white' : color}
            borderWidth={1}
            bg={currentStep ? color : 'white'}
            borderRadius="full"
            width={6}
            height={6}
            mr={2}>
            <Text color={currentStep ? 'white' : color}>{index}</Text>
          </HStack>
          <Text color="primary.900">
            {isDesktop
              ? maxStringLength(step.name, 65)
              : maxStringLength(step.name, 30)}
          </Text>
        </HStack>
        {step.completed ? (
          <HStack
            width={4}
            height={4}
            borderRadius="full"
            bg="primary.700"
            justifyContent="center"
            alignItems="center">
            <FaIcon color="white" name="check" size={10} />
          </HStack>
        ) : (
          <Box
            width={4}
            height={4}
            borderRadius="full"
            borderColor="muted.300"
            borderWidth={2}></Box>
        )}
      </HStack>
      {currentStep && (
        <HStack px={3} width="100%" mt={2} mb={4}>
          <Button
            onPress={handleNavigation}
            variant="outline"
            width="49%"
            mr={1}>
            <Text color="primary.700" fontWeight={500}>
              Details
            </Text>
          </Button>
          <Button
            onPress={() => {
              analytics.track('Path Started', {
                title: pathDetails.name,
                focusArea: focusArea,
                progress: pathDetails.stepsCompleted,
                url: window.location?.href,
              })

              analytics.track('Focus Area Started', {
                path: pathDetails.name,
                title: focusArea,
                progress: pathDetails.stepsCompleted,
              })

              if (step.activityId === 'COMPASS') {
                navigation.navigate('Compass')
              } else {
                navigation.navigate('Activity', {
                  activityId: step.activityId,
                  stepId: step.id,
                })
              }
            }}
            ml={1}
            width="49%">
            <Text color="white" fontWeight={500}>
              Continue
            </Text>
          </Button>
        </HStack>
      )}
    </VStack>
  )
}

export default AccordionItem

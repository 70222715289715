import { Box, Hidden, HStack } from 'native-base'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useUserProfileContext } from '../../providers/UserProfileProvider'
import { useBalancedSelf } from '../../queries/voyageQueries'
import NavFooterLink from './NavFooterLink'

const NavFooter = ({ activeLabel }) => {
  const safeAreaInsets = useSafeAreaInsets()
  const { userProfile } = useUserProfileContext()
  const { data, isLoading } = useBalancedSelf()

  const voyagePath =
    !isLoading && data.screener === 'COMPLETE' ? 'LandingVoyage' : 'Voyage'

  const links = [
    {
      label: 'Today',
      iconName: 'house',
      href: 'Home',
    },
    {
      label: 'Explore',
      iconName: 'binoculars',
      href: 'Explore',
    },
    {
      label: 'Voyage',
      iconName: 'lighthouse',
      href: voyagePath,
    },
    {
      label: 'Admin',
      iconName: 'wrench',
      href: 'Admin',
    },
    {
      label: 'Profile',
      iconName: 'user',
      href: 'Profile',
    },
  ]

  return (
    <Hidden from="sm">
      <Box w="100%" bg="white" shadow="9" safeAreaBottom>
        <HStack
          w="100%"
          justifyContent="space-around"
          px="10"
          pt={safeAreaInsets.bottom ? '3' : '1'}
          pb={safeAreaInsets.bottom ? '0' : '1'}>
          {links.map((link, index) => {
            let render = () => {
              return (
                <NavFooterLink
                  testID={`nav-footer-link-${link.label}`}
                  key={index}
                  label={link.label}
                  iconName={link.iconName}
                  href={link.href}
                  isActive={link.label === activeLabel}
                />
              )
            }
            if (userProfile.admin || link.label !== 'Admin') return render()
          })}
        </HStack>
      </Box>
    </Hidden>
  )
}

export default NavFooter

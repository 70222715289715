import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, HStack, ScrollView, VStack } from 'native-base'
import { Platform } from 'react-native'
import analytics from '../../../../../domain/services/analytics/AnalyticsService'
import { useRenderFocusAreaIcon } from '../../../hooks/useRenderFocusAreasIcon'
import { MemberScreenParams } from '../../../screens/MemberScreen'
import BackButton from '../../navigation/BackButton'
import { SkeletonLoader } from '../../_shared/SkeletonLoader'
import FocusAreaHeading from '../FocusAreaHeading'
import VoyageChip from '../VoyageChip'
import PathStep from './PathStep'

const LoadingView = () => (
  <VStack justifyContent="center" alignItems="center" py={4}>
    {Array.from({ length: 4 }).map((_, index) => (
      <SkeletonLoader
        key={index}
        mb={4}
        size={{ width: '100%', height: '40px' }}
      />
    ))}
  </VStack>
)
const PathMobile = ({ data, isLoading, focusAreaName, orderedSteps }) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  const { icon, color } = useRenderFocusAreaIcon(focusAreaName)

  const firstIncompleteStep = orderedSteps.findIndex(
    (step) => step.completed === false
  )

  return (
    <>
      <HStack
        width="100%"
        alignItems="flex-end"
        justifyContent="space-between"
        height={98}
        backgroundColor="white"
        borderBottomWidth={1}
        px={6}
        shadow={0.5}
        borderBottomColor="muted.50"
        pb={2}>
        <BackButton customText="" />
        {!isLoading && <LoadingView />}
        {!isLoading && data && (
          <VoyageChip
            bg={data.stepsCompleted > 0 ? 'success.200' : 'muted.200'}
            text={`${data.stepsCompleted}/${data.steps.length}`}
          />
        )}
      </HStack>
      <ScrollView p={4} showsVerticalScrollIndicator={false}>
        {data && (
          <VStack>
            <FocusAreaHeading
              fontSize="12px"
              size={5}
              iconSize={10}
              name={focusAreaName}
              description={data.description}
              color={color}
              icon={icon}
              ml={1}
              pathName={data.name}
            />
            {orderedSteps.map((step, index) => (
              <PathStep
                key={index}
                step={step}
                index={index}
                firstIncompleteStep={firstIncompleteStep}
                goToActivity={() => {
                  analytics.track('Path Started', {
                    title: data.name,
                    focusArea: focusAreaName,
                    progress: data.stepsCompleted,
                    url: window.location?.href,
                  })

                  analytics.track('Focus Area Started', {
                    path: data.name,
                    title: focusAreaName,
                    progress: data.stepsCompleted,
                  })

                  if (step.id === 'COMPASS') {
                    navigation.navigate('Compass')
                  } else {
                    navigation.navigate('Activity', {
                      activityId: step.id,
                      stepId: step.stepId,
                    })
                  }
                }}
                dataLength={orderedSteps.length}
              />
            ))}
          </VStack>
        )}
        {Platform.OS === 'ios' && <Box height="50px" />}
      </ScrollView>
    </>
  )
}

export default PathMobile

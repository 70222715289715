import { Box, Flex, Select, Text } from 'native-base'
import { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import AttendeeRole from '../../../../../domain/enums/AttendeeRole'
import useRoomClientContext from '../../../providers/RoomClientProvider'
import useRoomContext from '../../../providers/RoomProvider'
import FormLabel from '../../_shared/form/FormLabel'
import MicMeasurement from './MicMeasurement'
import PermissionCheck from './PermissionCheck'

const DeviceSettings = ({ hasCamera = false, role = null, isRoom = false }) => {
  const roomClient = useRoomClientContext()
  const room = useRoomContext()

  /* State */
  const defaultDevice = { value: 'default', label: 'Default device' }
  const noMic = { value: 'no-mic', label: 'No microphone' }
  const [micOptions, setMicOptions] = useState(
    Platform.OS === 'web' ? [noMic] : [defaultDevice, noMic]
  )
  const [cameraOptions, setCameraOptions] = useState([defaultDevice])
  /* Functions */
  async function fetchDeviceOptions(kind) {
    if (Platform.OS === 'web' && roomClient.hasPermission) {
      // Determine microphone or camera
      const isAudioInput = kind === 'audioinput'
      const defaultLabel = isAudioInput ? 'Microphone' : 'Camera'
      const setOptions = isAudioInput ? setMicOptions : setCameraOptions

      const newOptions = [defaultDevice] // Initialize options array with default device

      const deviceInfos = await navigator.mediaDevices.enumerateDevices()

      deviceInfos.forEach((deviceInfo, index) => {
        const option = {
          value: deviceInfo.deviceId,
          label: deviceInfo.label || `Unnamed ${defaultLabel} ${index + 1}`,
        }

        if (deviceInfo.kind === kind && deviceInfo.deviceId !== 'default') {
          newOptions.push(option)
        }
      })

      // Push the "No microphone" option to the end of the array
      if (isAudioInput && role !== AttendeeRole.Moderator && !isRoom) {
        newOptions.push({ value: 'no-mic', label: 'No microphone' })
      }

      setOptions(newOptions)
    }
  }

  /* Hooks */
  // Get mic/camera options
  useEffect(() => {
    ;(async () => {
      await fetchDeviceOptions('audioinput')

      if (hasCamera) {
        await fetchDeviceOptions('videoinput')
      }
    })()
  }, [roomClient.hasPermission])

  const handleMicChange = (value) => {
    if (room.me.role !== AttendeeRole.Moderator) {
      if (value === 'no-mic') {
        roomClient.setUserHasMic(false)
      } else {
        roomClient.setUserHasMic(true)
      }
    }
    roomClient.setMicId(value)
  }

  useEffect(() => {
    if (roomClient.hasPermission !== null && !roomClient.hasPermission) {
      roomClient.setUserHasMic(false)
      roomClient.setMicId('no-mic')
    }
  }, [roomClient])

  useEffect(() => {
    if (roomClient) {
      roomClient.setMicId(defaultDevice.value)
    }
  }, [])

  return (
    <>
      <PermissionCheck hasCamera={hasCamera && cameraOptions.length > 0} />
      <Flex mb="5">
        <FormLabel color="muted.500">Microphone</FormLabel>
        <Select
          selectedValue={roomClient.micId}
          defaultValue={defaultDevice.value}
          placeholder="Select Microphone"
          size="lg"
          onValueChange={handleMicChange}
          onOpen={() => fetchDeviceOptions('audioinput')}>
          {micOptions.length > 0 &&
            micOptions.map((option, index) => (
              <Select.Item
                key={index}
                value={option.value}
                label={option.label}
              />
            ))}
        </Select>
        {roomClient.userHasMic && <MicMeasurement />}
        {roomClient.hasPermission &&
          !roomClient.userHasMic &&
          role !== AttendeeRole.Moderator && (
            <Box mt={4}>
              <Text fontWeight="bold" color="primary.900">
                No microphone?
              </Text>
              <Text color="primary.900">
                • You’ll still be able to join and participate!
              </Text>
              <Text color="primary.900">
                • Remember you’re to able use the hand-raise and chat functions
              </Text>
            </Box>
          )}
      </Flex>
      {hasCamera && cameraOptions.length > 0 && (
        <Flex mb="5">
          <FormLabel color="muted.500">Camera</FormLabel>
          <Select
            selectedValue={roomClient.cameraId}
            defaultValue={defaultDevice.value}
            placeholder="Select Camera"
            size="lg"
            onValueChange={roomClient.setCameraId}
            onOpen={() => fetchDeviceOptions('videoinput')}>
            {cameraOptions.map((option, index) => (
              <Select.Item
                key={index}
                value={option.value}
                label={option.label}
              />
            ))}
          </Select>
        </Flex>
      )}
    </>
  )
}

export default DeviceSettings

import { Box, HStack, Image, Text, VStack } from 'native-base'
import { StyleSheet, Text as TextNative } from 'react-native'
import { useBalancedSelf } from '../../../queries/voyageQueries'
import VideoPlayer from '../../explore/video/VideoPlayer.web'
import BackButton from '../../navigation/BackButton'
import { SkeletonLoader } from '../../_shared/SkeletonLoader'
import FocusAreasList from '../focusArea/FocusAreasList'

const BalancedSelf = require('../../../assets/images/voyages/balanced-self-desktop.png')

const LoadingView = () => (
  <VStack justifyContent="center" alignItems="center" py={4}>
    {Array.from({ length: 8 }).map((_, index) => (
      <SkeletonLoader
        key={index}
        mb={4}
        size={{ width: '100%', height: '40px' }}
      />
    ))}
  </VStack>
)

const styles = StyleSheet.create({
  bold: {
    fontFamily: 'Poppins_700Bold',
  },
})

// Update when Voyages videos is ready

const activity = {
  videoUrl: 'https://vimeo.com/903478011',
}

const BalancedSelfDesktop = () => {
  const { data, isLoading } = useBalancedSelf()

  return (
    <Box bg="muted.50" mb={6}>
      <BackButton />
      <HStack>
        <VStack mr={6} w="600px">
          <Box
            borderRadius="20px"
            bg="white"
            p={4}
            borderColor="grey.200"
            borderWidth={1}>
            <Text fontSize="24px" color="primary.900" fontWeight={500}>
              Balanced Self
            </Text>
            <Text color="primary.900" mt={2}>
              <Text>
                A balanced self starts with a balanced mind—emotional, logical,
                and agile. Each hosts a set of
              </Text>
              <TextNative style={styles.bold}>{` focus areas `}</TextNative>
              <Text>
                designed to improve your well-being, while allowing you to track
                the progress of your journey.
              </Text>
            </Text>
            <Text color="primary.900" mt={6} mb={4} textAlign="center">
              <Text>Review the</Text>
              <TextNative style={styles.bold}>{` Voyage `}</TextNative>
              <Text>introduction video to learn more</Text>
            </Text>
            <Box width="462px" height="260px" mb={6} alignSelf="center">
              <VideoPlayer
                activity={activity}
                videoAspectRatioCallback={3 / 2}
              />
            </Box>
            <Box mt={10} width="100%" px={10} alignSelf="center">
              <Image
                height="1000px"
                source={BalancedSelf}
                alt="Balanced self focus areas"
              />
            </Box>
          </Box>
        </VStack>
        {isLoading ? (
          <VStack w={'35%'}>
            <Box
              borderRadius="20px"
              bg="white"
              p={4}
              borderColor="grey.200"
              borderWidth={1}>
              <LoadingView />
            </Box>
          </VStack>
        ) : (
          <FocusAreasList data={data} isBalancedSelfScreen />
        )}
      </HStack>
    </Box>
  )
}

export default BalancedSelfDesktop

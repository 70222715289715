import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { format, isSameDay, parseISO } from 'date-fns'
import { Button, HStack, Spinner, Text, View } from 'native-base'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import { MemberScreenParams } from '../../screens/MemberScreen'
import { useVibeCheckHistory } from '../vibeCheck/queries'
import FaIcon from '../_shared/FaIcon'

const WeeklyVibeCheck = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  const { data, isLoading } = useVibeCheckHistory()
  const vibeCheckHistory = data?.pages[0].content
  const isDesktop = useDesktopBreakpoint()

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const lastMonday = new Date()
  lastMonday.setDate(today.getDate() - ((today.getDay() + 6) % 7))
  lastMonday.setHours(0, 0, 0, 0)

  const isDataAvailable = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd')
    return vibeCheckHistory?.some((entry) => {
      const entryDate = parseISO(entry.completedAt)
      const entryFormattedDate = format(entryDate, 'yyyy-MM-dd')
      return entryFormattedDate === formattedDate
    })
  }

  const renderDayString = (date) => {
    // previous date with vibe check filled
    if (isSameDay(date, today) && isDataAvailable(date)) {
      return { icon: 'check', color: 'green.600' }
    }
    if (isSameDay(date, lastMonday)) {
      return isDataAvailable(date)
        ? { icon: 'check', color: 'green.600' }
        : { icon: 'circle', color: 'muted.600' }
    }
    // previous date with no vibe check
    if (date < today) {
      return { icon: 'circle', color: 'muted.600' }
    }
    // future date
    return { icon: 'circle', color: 'muted.300' }
  }

  return (
    <HStack flexDirection="column">
      {isLoading && <Spinner />}
      <Text color="primary.900" fontSize="16px" fontWeight={700} my={5}>
        This Week
      </Text>
      <View
        flexDirection="row"
        alignSelf="center"
        width={isDesktop ? '425px' : '100%'}
        justifyContent={isDesktop ? 'space-around' : 'space-between'}>
        {[0, 1, 2, 3, 4, 5, 6].map((offset) => {
          const day = new Date()
          day.setDate(lastMonday.getDate() + offset)
          const formattedDate = format(day, 'EEEE').charAt(0)
          const dayString = renderDayString(day)

          return (
            <View key={day.getTime()} alignItems="center">
              <FaIcon name={dayString.icon} color={dayString.color} size={24} />
              <Text mt={2} color="primary.900" fontSize="16px" fontWeight={700}>
                {formattedDate}
              </Text>
            </View>
          )
        })}
      </View>
      {!isDesktop && (
        <Button
          pr={0}
          variant="link"
          alignSelf="flex-end"
          onPress={() => navigation.navigate('VibeCheckHistory')}>
          <Text
            textDecorationLine="underline"
            color="primary.900"
            fontSize="12px">
            View history
          </Text>
        </Button>
      )}
    </HStack>
  )
}

export default WeeklyVibeCheck

import { useNavigation } from '@react-navigation/native'
import { Button, Flex, ScrollView, StatusBar, Text } from 'native-base'
import { useMobileBreakpoint } from '../../../domain/services/useBreakpoint'
import BackButton from '../components/navigation/BackButton'
import KeyboardDismissOverlay from '../components/_shared/layout/KeyboardDismissOverlay'

const FormLayout = ({
  hasSafeAreaY = true,
  padding = '5',
  statusBarStyle = 'dark-content',
  children,
  hasScrollBar = true,
  hasKeyboardDismissOverlay = true,
  autoBackButton = true,
  bg = 'white',
  extraButton = {
    text: null,
    navigation: null,
  },
  minH = '700px',
}) => {
  const isMobile = useMobileBreakpoint()
  const navigation = useNavigation()

  const childrenWrapper = isMobile ? (
    <Flex bg={bg} h="100%" p={padding} safeAreaY={hasSafeAreaY ? true : null}>
      {autoBackButton && (
        <Flex
          zIndex={10}
          mt={'-20px'}
          mb={'20px'}
          flexDirection={isMobile ? 'row' : 'column'}>
          <BackButton customText="" hasText={false} />
          {extraButton?.navigation && (
            <Button
              variant="link"
              mr={'-10px'}
              onPress={() => navigation.navigate(extraButton.navigation)}>
              <Text fontWeight={700} fontSize="16px">
                {extraButton.text}
              </Text>
            </Button>
          )}
        </Flex>
      )}
      {children}
    </Flex>
  ) : (
    <Flex
      bg={bg}
      w="400px"
      minH={minH}
      p={padding}
      m="auto"
      borderRadius="3xl"
      shadow="5">
      {children}
    </Flex>
  )

  const scrollViewWrapper = (
    <>
      <StatusBar barStyle={statusBarStyle} />
      {hasScrollBar ? (
        <ScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          keyboardShouldPersistTaps="handled"
          bounces={false}>
          {childrenWrapper}
        </ScrollView>
      ) : (
        <Flex flex="1">{childrenWrapper}</Flex>
      )}
    </>
  )

  return hasKeyboardDismissOverlay ? (
    <KeyboardDismissOverlay>{scrollViewWrapper}</KeyboardDismissOverlay>
  ) : (
    scrollViewWrapper
  )
}

export default FormLayout

import { addDays, differenceInDays } from 'date-fns'
import { Box, Flex, Spinner, StatusBar } from 'native-base'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useGetOasisColors from '../../../hooks/useGetOasisColors'
import ExploreContainer from '../../_shared/layout/ExploreContainer'
import GroupsOnDateList from '../GroupsOnDateList'
import DateSelectionMobile from './DateSelectionMobile'

const today = new Date()
today.setHours(0, 0, 0, 0)

const GroupCalendarViewMobile = ({ dateEvents, onLiveGroupPress }) => {
  const { tabColor } = useGetOasisColors()
  const [loading, setLoading] = useState(
    () => new Date(dateEvents[0].date) < new Date()
  )
  const userPickedDate = useRef(false)
  const [selectedDateIndex, setSelectedDateIndex] = useState(() => {
    const index = dateEvents.findIndex(
      (item) => item.type == 'title' && item.date.toString() == today.toString()
    )
    return index
  })
  const flatListRef = useRef(null)
  const sectionListRef = useRef(null)

  const onScrollAnimationEnd = useCallback(() => {
    setLoading(false)
  }, [])

  const calendar = useMemo(() => {
    if (!dateEvents || dateEvents.length == 0) return []
    const titles = dateEvents.filter((item) => item.type === 'title')
    const calendar = []
    let lastDate = calendar[0]?.date
    for (let title of titles) {
      if (title.title.includes('-')) {
        const startDate = addDays(lastDate, 1)
        const difference = differenceInDays(title.date, startDate)
        for (let i = 0; i <= difference; i++) {
          const item = {
            date: addDays(startDate, i),
            index: title.index,
            title: title.title,
            type: 'title',
          }
          calendar.push(item)
        }
      } else calendar.push(title)
      lastDate = title.date
    }
    return calendar
  }, [dateEvents])

  useEffect(() => {
    setTimeout(() => {
      const calendarIndex = calendar.findIndex(
        (item) => item.date.toString() == today.toString()
      )
      sectionListRef.current.scrollToIndex({
        animated: false,
        index: selectedDateIndex,
      })
      flatListRef.current?.scrollToIndex({
        animated: false,
        index: calendarIndex - 2 > 0 ? calendarIndex - 2 : 0,
      })
      setSelectedDateIndex(calendarIndex)
    }, 250)
  }, [])

  const onScrollBegin = useCallback(() => {
    userPickedDate.current = false
  }, [])

  const flatListScrollIndex = useCallback((index) => {
    flatListRef.current?.scrollToIndex({
      animated: true,
      index: index - 2 > 0 ? index - 2 : 0,
    })
  }, [])

  const onDatePress = useCallback(
    (index, date) => {
      if (loading) return
      userPickedDate.current = true
      const calendarIndex = calendar.findIndex(
        (item) => item.index == index && item.date.toString() == date.toString()
      )
      setSelectedDateIndex(calendarIndex)
      flatListScrollIndex(calendarIndex)
      sectionListRef.current.scrollToIndex({
        animated: true,
        index: index,
      })
    },
    [calendar, setSelectedDateIndex, loading]
  )

  const setIndexOnScroll = useCallback(
    (date) => {
      if (!userPickedDate.current) {
        const calendarIndex = calendar.findIndex(
          (item) => item.date.toString() == date.toString()
        )
        setSelectedDateIndex(calendarIndex)
        flatListScrollIndex(calendarIndex)
      }
    },
    [calendar, setSelectedDateIndex]
  )

  const group = useMemo(
    () => (
      <GroupsOnDateList
        dateEvents={dateEvents}
        sectionListRef={sectionListRef}
        setIndexOnScroll={setIndexOnScroll}
        onScrollBegin={onScrollBegin}
        onLiveGroupPress={onLiveGroupPress}
        onScrollAnimationEnd={onScrollAnimationEnd}
        visible={!loading}
      />
    ),
    [calendar, dateEvents, selectedDateIndex, loading]
  )

  return (
    <Flex bg={tabColor} h="100%">
      <ExploreContainer>
        <StatusBar barStyle="light-content" />
        <DateSelectionMobile
          flatListRef={flatListRef}
          calendar={calendar}
          selectedDateIndex={selectedDateIndex}
          onDatePress={onDatePress}
          visible={!loading}
        />
        {loading && (
          <Box bg="white" borderTopRadius="2xl" h="100%">
            <Spinner size={'lg'} color="secondary.600" mr={2} top="100px" />
          </Box>
        )}
        {group}
      </ExploreContainer>
    </Flex>
  )
}

export default GroupCalendarViewMobile

import {
  Box,
  Button,
  HStack,
  Image,
  ScrollView,
  Text,
  VStack,
} from 'native-base'
import { useEffect, useState } from 'react'
import { StyleSheet, Text as TextNative } from 'react-native'
import WebView from 'react-native-webview'
import { useBalancedSelf } from '../../../queries/voyageQueries'
import BackButton from '../../navigation/BackButton'
import FaIcon from '../../_shared/FaIcon'
import { SkeletonLoader } from '../../_shared/SkeletonLoader'
import FocusAreasList from '../focusArea/FocusAreasList'

const BalancedSelf = require('../../../assets/images/voyages/balanced-self-mobile.png')

const LoadingView = () => (
  <VStack justifyContent="center" alignItems="center" py={4}>
    {Array.from({ length: 8 }).map((_, index) => (
      <SkeletonLoader
        key={index}
        mb={4}
        size={{ width: '100%', height: '40px' }}
      />
    ))}
  </VStack>
)

const styles = StyleSheet.create({
  bold: {
    fontFamily: 'Poppins_700Bold',
  },
})

const BalancedSelfMobile = () => {
  const { data, isLoading } = useBalancedSelf()
  const [showVideo, setShowVideo] = useState(false)
  const [loading, setLoading] = useState(false)
  const [videoUrl, setVideoUrl] = useState('')

  useEffect(() => {
    fetch('https://player.vimeo.com/video/903541826/config')
      .then((res) => res.json())
      .then((res) => {
        setVideoUrl(
          res.request.files.hls.cdns[res.request.files.hls.default_cdn].url
        )
      })
  }, [])

  return (
    <>
      <HStack
        width="100%"
        alignItems="flex-end"
        justifyContent="space-between"
        height={98}
        backgroundColor="white"
        borderBottomWidth={1}
        px={6}
        shadow={0.5}
        borderBottomColor="muted.50"
        pb={2}>
        <BackButton customText="" />
      </HStack>
      <ScrollView showsVerticalScrollIndicator={false}>
        <VStack p={4}>
          <Text fontSize="24px" color="primary.900" fontWeight={500}>
            Balanced Self
          </Text>
          <Text color="primary.900" mt={2}>
            <Text>
              A balanced self starts with a balanced mind—emotional, logical,
              and agile. Each hosts a set of
            </Text>
            <TextNative style={styles.bold}>{` focus areas `}</TextNative>
            <Text>
              designed to improve your well-being, while allowing you to track
              the progress of your journey.
            </Text>
          </Text>
        </VStack>
        {isLoading ? (
          <VStack w={'35%'}>
            <Box
              borderRadius="20px"
              bg="white"
              p={4}
              borderColor="grey.200"
              borderWidth={1}>
              <LoadingView />
            </Box>
          </VStack>
        ) : (
          <>
            <FocusAreasList data={data} isBalancedSelfScreen />
            <Text color="primary.900" mt={6} mb={4} textAlign="center">
              <Text>Review the</Text>
              <TextNative style={styles.bold}>{` Voyage `}</TextNative>
              <Text>{`introduction\nvideo to learn more`}</Text>
            </Text>
            <HStack
              alignSelf="center"
              width="196px"
              borderColor="primary.700"
              borderWidth={1}
              borderRadius="4px"
              justifyContent="center"
              alignItems="center"
              flexDirection="row">
              <FaIcon size={20} name="play" isSolid color="primary.700" />
              <Button
                isLoading={loading}
                onPress={() => {
                  setShowVideo(true)
                  setTimeout(() => {
                    setLoading(false)
                  }, 5000)
                }}
                variant="unstyled">
                <Text fontWeight={500}>View video</Text>
              </Button>
            </HStack>
          </>
        )}
        <Image
          alignSelf="center"
          source={BalancedSelf}
          alt="Balanced self focus areas"
        />
      </ScrollView>
      {showVideo && (
        <WebView
          source={{ uri: videoUrl }}
          onLoadStart={() => setLoading(true)}
          onLoad={() => {
            setLoading(false)
          }}
          onLoadEnd={() => setLoading(false)}
          onError={() => setLoading(false)}
        />
      )}
    </>
  )
}

export default BalancedSelfMobile
